import { Injectable } from '@angular/core';
import { SessionDataService } from 'app/shared/services';
import { Route, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable()
export class GroupGaurd implements CanActivate{

	constructor(private session_data: SessionDataService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) : boolean {
		let allowed_groups: string[] = route.data.groups;
		let user_groups: string[] = this.session_data.CurrentUser.Groups;

		if (this.session_data.CurrentUser.Role == "Admin") return true;

		for (let group of allowed_groups)
			if (user_groups.indexOf(group) > -1) return true;
		this.session_data.navigateBack();
	}

}
