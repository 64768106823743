import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[qm-clipboard]'
})
export class ClipboardDirective {

	@Input('qm-clipboard') clip_text: string;

	@HostListener('click', ['$event.target']) onClick(event){
		this.copy();
	}

	constructor() { }

	copy() {
		let selBox = document.createElement('textarea');
		selBox.style.opacity = '0';
		selBox.value = this.clip_text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}
}
