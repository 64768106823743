import { Injectable } from '@angular/core';
import { SessionDataService } from './session-data.service';
import { Observable } from 'rxjs';
import { User } from '../classes';
import { ApiResponse } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private static ENDPOINT = '/users';

  constructor(private sessionDataService: SessionDataService) { }

  getUsersInGroup(group: String | String[], limit: Number = 100) {
    const groupString = (Array.isArray(group)) ? group.join(',') : group;
    const url = `${UserService.ENDPOINT}?group=${groupString}&limit=${limit}`;
    return this.sessionDataService.apiGet(url).map((response: ApiResponse<User[]>) => {
      return response.result;
    });
  }

}
