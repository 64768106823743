import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { SessionDataService } from 'app/shared/services';


@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

	private errorMessage: string;

	constructor(private route: ActivatedRoute, private session_data: SessionDataService) { }

	ngOnInit() {
		this.errorMessage = this.route.snapshot.data['message'];
	}

	sendHome() {
		this.errorMessage = null;
		this.session_data.Router.navigate(['/']);
	}

}
