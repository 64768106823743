import { Reference } from 'app/shared/interfaces';

export class User {

	public id: Function;
	private slack_id: string;
	private name: string;
	private email: string;
	private role: string;
	private title: string;
	private phone: string;
	private extension: string;
	private username: string;
	private groups: string[];
	private department: string;
	private date_hired: Date;
	private gitlab_token: string;

	constructor(_id?: string) {
		this.id = () => _id;
	}

	toReference(): Reference {
		return { "id": this.id(), "name": this.name };
	}

	get SlackID() { return this.slack_id; }
	set SlackID(slack_id: string) { this.slack_id = slack_id; }

	get Role() { return this.role; }
	set Role(role: string) { this.role = role; }

	get Name() { return this.name; }
	set Name(name: string) { this.name = name; }

	get Phone() { return this.phone; }
	set Phone(phone: string) { this.phone = phone; }

	get Extension() { return this.extension; }
	set Extension(extension: string) { this.extension = extension; }

	get Department() { return this.department; }
	set Department(department: string) { this.department = department; }

	get DateHired() { return this.date_hired; }
	set DateHired(date_hired: Date) { this.date_hired = date_hired; }

	get Email() { return this.email; }
	set Email(email: string) { this.email = email; }

	get Title() { return this.title; }
	set Title(title: string) { this.title = title; }

	get GitlabToken() { return this.gitlab_token; }
	set GitlabToken(gitlab_token) { this.gitlab_token = gitlab_token; }

	get Groups(): string[] { return this.groups || []; }
	set Groups(groups: string[]) { this.groups = groups; }
	get GroupList(): string { return this.groups.join(", ") }

	get Username(): string { return this.username; }
	set Username(username: string) { this.username = username; }

	addGroup(group){
		if(this.groups == undefined) this.groups = [];
		if(this.groups.indexOf(group) == -1){
			this.groups.push(group);
		}
	}

	public memberOf(group_name: string): boolean {
		for (let g of this.groups)
			if (g == group_name) return true;
		return false;
	}


	static dbImport(source: Object) {
		let user = new User(source['_id']);
		for (let property of Object.keys(source)) {
			if (property == "_id") continue;
			user[property] = source[property];
		}
		return user;
	}

}
