import { Component, Input } from '@angular/core';
import { Project, Bid } from 'app/shared/classes';

@Component({
	selector: 'qm-client-project-item',
	template: `
		<div class="date-created">{{item.DateCreated | date:'yyyy-MM-dd'}}</div><!--
	 --><div class="number">{{item.Number}}</div><!--
	 --><div class="name">{{item.Name}}</div><!--
	 --><div class="project-status">{{item.InvoicingStatus || item.Status}}</div><!--
	 --><div class="project-pm">{{ item.constructor.name  == 'Project' ? item.ProjectManager?.name : item.SalesRep?.name }}</div><!--
	 --><div class="study-type">{{item.StudyType}}</div>`,
	styleUrls: ['./client-project-item.component.scss']
})
export class ClientProjectItem{

	@Input() item: Project|Bid;

	constructor(){}

}
