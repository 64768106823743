import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Note } from 'app/shared/classes';
import { FormGroup , Validators, FormControl} from '@angular/forms';
import { SessionDataService } from 'app/shared/services';
import { Subscription } from 'rxjs';


@Component({
	selector: 'qm-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {

	@Input('allow-small') allow_small: boolean;
	@Input('path') path: string;
	private new_note_form: FormGroup;
	private editing_note_index: number;
	private notes: Note[];
	private is_adding_note: boolean = false;

	private get_notes_sub: Subscription;
	private post_notes_sub: Subscription;
	private put_notes_sub: Subscription;
	private delete_notes_sub: Subscription;

	private is_loading: boolean = true;

	constructor(private session_data: SessionDataService) {}

	ngOnChanges(){
		this.loadNotes();
	}

	loadNotes(){
		this.get_notes_sub = this.session_data.apiGet(this.path).subscribe(response => {
			this.is_loading = false;
			this.notes = response['result'].map(note =>  Note.dbImport(note));
		});
	}



	ngOnInit(){
		this.new_note_form = new FormGroup({
			'author': new FormControl(this.session_data.CurrentUser.toReference()),
			'content': new FormControl('', Validators.required)
		});
		if (this.allow_small == undefined) this.allow_small = true;
	}

	onAddNote(){
		this.editing_note_index = undefined;
		this.is_adding_note = true;
	}

	resetForm(){
		this.is_loading = false;
		this.is_adding_note = false;
		this.new_note_form.reset({'author': this.session_data.CurrentUser.toReference()});
	}

	onSaveNote(){
		let note: Note = Note.dbImport(this.new_note_form.value);
		this.is_loading = false;
		// replace existing note
		if (this.editing_note_index !== undefined){
			let note_id = this.notes[this.editing_note_index].id();
			console.log("note", note);
			this.put_notes_sub = this.session_data.apiPut(this.path + "/" + note_id, note).subscribe(
				okay => {
					note.setID(note_id);
					this.notes[this.editing_note_index] = note;
					this.editing_note_index = undefined;
					this.resetForm();
				},
				this.session_data.apiErrorHandler
			);
		// create new note.
		}else{
			this.post_notes_sub = this.session_data.apiPost(this.path, note).subscribe(
				response => {
					note.setID(response['result']['_id']);
					this.notes.unshift(note);
					this.resetForm();
				},
				this.session_data.apiErrorHandler
			);
		}
	}

	onEditNote(note_index: number){
		this.editing_note_index  = note_index;
		this.new_note_form.controls['content'].setValue(this.notes[note_index]['content']);
		this.is_adding_note = true;
	}

	confirmDeleteNote(note_index: number){
		this.session_data.confirmationCheck().subscribe(data => {
			if(data === true){
				this.onDeleteNote(note_index);
			}
		});
	}

	onDeleteNote(note_index:number){
		this.is_loading = true;
		this.delete_notes_sub = this.session_data.apiDelete(this.path + "/" + this.notes[note_index].id()).subscribe(
			success => this.notes.splice(note_index, 1),
			this.session_data.apiErrorHandler,
			() => this.is_loading = false
		);
	}

	ngOnDestroy(){
		if(this.get_notes_sub) this.get_notes_sub.unsubscribe();
		if(this.put_notes_sub) this.put_notes_sub.unsubscribe();
		if(this.post_notes_sub) this.post_notes_sub.unsubscribe();
		if(this.delete_notes_sub) this.delete_notes_sub.unsubscribe();
	}


}
