import { Injectable } from '@angular/core';
import { SessionDataService } from 'app/shared/services/session-data.service';

@Injectable()
export class PayoutService {

	private payout: Object;

	get Payout(): Object { return this.payout; }
	set Payout(payout: Object) { this.payout = payout; }

	constructor(private session_data: SessionDataService) {}

	public reload(){
		this.session_data.apiGet(`/finance/payouts/${this.payout['_id']}`).subscribe(
			data => {
				this.Payout = data['result'];
			}
		);
	}

	public reset(){
		this.session_data.apiPost(`/finance/payouts/${this.payout['_id']}/reset`,{}).subscribe(
			data => {
				console.log("Transaction Reset",data);
			},e => {
				console.error("Transaction Reset Error",e);
			},() => {
				this.reload();
			}
		);
	}
}
