import { Component, Input} from '@angular/core';

@Component({
	selector: 'qm-payout-method-icon',
	template: `
	<span [ngSwitch]="method">
		<span *ngSwitchCase="'paypal'" class="status-icon"><i class="fab fa-paypal fg-paypal-blue"></i> PayPal</span>
		<span *ngSwitchDefault>{{method}}</span>
	</span>
	`,
	styles: []
})
export class PayoutMethodIconComponent {
	@Input('method') method: string;
	constructor() { }
}
