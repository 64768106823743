import { Component, OnInit } from '@angular/core';
import { SessionDataService } from 'app/shared/services';

@Component({
	selector: 'qm-refresh-selector',
	template: `
		<i class="far fa-clock"  title="Refresh Interval" (click)="nextInterval()"> {{session_data.RefreshInterval}}s</i>
	`,
	styleUrls: ['./refresh-selector.component.scss']
})
export class RefreshSelectorComponent implements OnInit {

	constructor(private session_data: SessionDataService) { }

	ngOnInit() {
	}

	nextInterval(){
		if(this.session_data.RefreshInterval >= 90){
			this.session_data.RefreshInterval = 5;
		}else if(this.session_data.RefreshInterval == 5){
			this.session_data.RefreshInterval = 15;
		}else{
			this.session_data.RefreshInterval += 15;
		}
	}

}
