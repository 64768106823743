import { Component, Input } from '@angular/core';

@Component({
	selector: 'qm-loading-icon',
	templateUrl: './loading-icon.component.html',
	styleUrls: ['./loading-icon.component.scss']
})
export class LoadingIconComponent {

	@Input() style: string = "spinner";
	@Input() message: string = "";
	@Input() scale: number = 1;

	constructor() { }
}
