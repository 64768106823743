import { Injectable }    from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable }    from 'rxjs/Observable';

export interface CanComponentDeactivate {
 canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

	constructor(){}

	canDeactivate(component: any,
								route: ActivatedRouteSnapshot,
								state: RouterStateSnapshot) {
		return component.canDeactivate ? component.canDeactivate() : true;
	}

}
