// Auth constraint must be imported directly from this file (not the index)
// Reference: https://github.com/angular/angular-cli/issues/5769#issuecomment-298079583
import { AuthConstraint } from 'app/shared/classes/auth-constraint';
import { Reference, Panel } from 'app/shared/interfaces';

interface redirects {
	"complete": string;
	"terminate": string;
	"over_quota": string;
	"security_terminate": string;
}

interface parameter {
	"key": string,
	"internal": boolean,
	"varies_by_status"?: boolean,
	"source"?: string,
	"field"?: string
}
interface mods {
	"hmac": {
		"enabled": boolean,
		"concat"?: string[],
		"algorithm"?: string
	},
	"sccb": {
		"enabled": boolean,
		"options"?: {
			"method": string,
			"hostname": string,
			"path": string,
			"port": 443
		},
		"async"?: false
		"statuses"?: string[]
		// incomplete list of properties.  only including those needed for the front-end.
	}
}

export class Partner extends AuthConstraint {

	private name: string;
	private proprietary: boolean = false;
	private rl: number;
	private query_string: string;
	private parameters: parameter[] = [];
	private redirects: redirects = {
		"complete": "",
		"terminate": "",
		"over_quota": "",
		"security_terminate": ""
	};
	private mods: mods = {"hmac": {"enabled": false}, "sccb": {"enabled": false}};
	private panels: Panel[] = [];
	private created_by: Reference;
	private date_created: string;

	private website: string  = '';
	private study_types: string[] = [];
	private methodologies: string[] = [];
	private sampling_methods: string[] = [];
	private tags: string[] = [];
	private tier: number;
	private available_panels : {"name": string, "count": string}[] = [];
	private enabled = false;
	private contacts: {
		"name": string,
		"phone": string,
		"email": string
	}[] = [];

	get Name(): string { return this.name; }
	set Name(name: string) { this.name = name; }
	get IsProprietary(): boolean { return this.proprietary; }
	get RL(): number { return this.rl; }
	set RL(rl: number) { this.rl = rl; }
	get QueryString(): string { return this.query_string; }
	set QueryString(query_string: string) { this.query_string = query_string; }
	get Parameters() : parameter[] { return this.parameters; }
	set Parameters(parameters: parameter[]) { this.parameters = parameters; }
	get Redirects(): redirects { return this.redirects; }
	set Redirects(redirects: redirects) { this.redirects = redirects; }
	get Mods() : mods { return this.mods; }
	set Mods(mods: mods) { this.mods = mods; }
	get Panels(): Panel[] { return this.panels; }
	set Panels(panels: Panel[]) { this.panels = panels; }
	get CreatedBy(): Reference { return this.created_by; }
	set CreatedBy(creator: Reference) { this.created_by = creator; }
	get DateCreated(): string { return this.date_created; }

	get Website(): string { return this.website; }
	set Website(website: string) { this.website = website; }
	get StudyTypes() : string[] { return this.study_types; }
	set StudyTypes(study_types: string[]) { this.study_types = study_types; }
	get Methodologies() : string[] { return this.methodologies; }
	set Methodologies(methodologies: string[]) { this.methodologies = methodologies; }
	get SamplingMethods(): string[] { return this.sampling_methods; }
	set SamplingMethods(sampling_methods: string[]) { this.sampling_methods = sampling_methods; }
	get AvailablePanels() : {"name": string, "count": string}[] { return this.available_panels; }
	set AvailablePanels(available_panels: {"name": string, "count": string}[]) { this.available_panels = available_panels; }
	set Contacts(contacts) { this.contacts = contacts; }
	get Contacts() { return this.contacts; }
	get Tags() : string[] { return this.tags; }
	set Tags(tags: string[]) { this.tags = tags; }
	get Tier() : number { return this.tier; }
	set Tier(tier: number) { this.tier = tier; }
	get Enabled() : boolean { return this.enabled; }
	set Enabled(enabled: boolean) { this.enabled = enabled; }

	constructor(_id?: string) {
		super(_id);
	}

	public setDefaultMembers() {
		let add_created_by = true;
		for (let member of this.members)
			if (member.id == this.created_by.id){
				add_created_by = false;
				break;
			}
		if (add_created_by && this.created_by){
			this.members.push({
				"name": this.created_by.name,
				"id": this.created_by.id,
				"label": "Created By",
				"scopes": ["read", "write"],
				"role": "other"
			});
		}

		this.groups = [{
			"name": "project_managers",
			"scopes": ["read", "write"]
		}];
	}

	static dbImport(source: Object) {
		let partner = new Partner(source['_id']);
		for (let property of Object.keys(source)) {
			if (property == "_id") continue;
			partner[property] = source[property];
		}
		return partner;
	}

}
