export const environment = {
	production: true,
	name: 'QA',
	api_url: 'https://titan-api.stg.titan.questmindshare.com',
	sock_url: '',
	respondent_url: 'https://portal.qa.opinionchamp.com',
	mock_survey_url: 'https://mock-survey.qa.mindsharesurveys.com?id={ID}',
	webhook_url: 'https://webhook.qa.questtitan.com',
	quest_redirect_url: 'https://redirect.qa.mindsharesurveys.com/v1/ldrLkz20kLMsk7YJmBIWybAIgke8pxh9',
	version: require('../../package.json').version,
	vesion_check_url: '/static/version.json',
	launchDarklyClientSideId: '60522959d4808b0e359dce31',
	kronos_web_url: 'https://kate-t2-irt.vercel.app',
};
