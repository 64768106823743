import { Injectable } from '@angular/core';
import { ReferralCampaign } from '../interfaces';
import { SessionDataService } from './session-data.service';

interface IReferralCampaignsByCodeResponse {
  campaigns: ReferralCampaign[];
}

@Injectable({
  providedIn: 'root',
})
export class ReferralCampaignService {

  private static ENDPOINT = '/referral-campaigns';

  constructor(private session_data_service: SessionDataService) { }

  get() {
    return this.session_data_service.apiGet(ReferralCampaignService.ENDPOINT).map((response) => {
      return response['campaigns'];
    });
  }

  getReferralCampaignPerformance() {
    return this.session_data_service.apiGet(`${ReferralCampaignService.ENDPOINT}/performance`).map((response) => {
      return response;
    });
  }

  getReferralCampaign(referralCode: String) {
    const url = `${ReferralCampaignService.ENDPOINT}/code?code=${referralCode}`;
    return this.session_data_service.apiGet(url)
      .map((response: IReferralCampaignsByCodeResponse) => {
        if (response && Array.isArray(response.campaigns)) {
          return response.campaigns.shift();
        }
      });
  }

  isReferralCodeUnique(referralCode: String) {
    return this.getReferralCampaign(referralCode)
      .map((campaign: ReferralCampaign) => {
        return campaign == null;
      });
  }

  createReferralCampaign(referralCampaign: ReferralCampaign) {
    const url = `${ReferralCampaignService.ENDPOINT}`;
    return this.session_data_service.apiPost(url, referralCampaign)
      .map((response: ReferralCampaign) => {
        return response;
      });
  }

  updateReferralCampaign(referralCampaign: ReferralCampaign) {
    const url = `${ReferralCampaignService.ENDPOINT}/${referralCampaign._id}`;
    return this.session_data_service.apiPatch(url, referralCampaign)
      .map((response: ReferralCampaign) => {
        return response;
      });
  }
}
