import { Component, Input, Output, OnInit, OnChanges, OnDestroy, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SessionDataService } from 'app/shared/services';
import { Reference, Accessor } from 'app/shared/interfaces';
import { AuthConstraint } from 'app/shared/classes';
import { Subscription, Observable, Subject, Observer } from 'rxjs';

/*

Methods of USE:
@params:
	- accessChanged:  callback when access has been changed
	- resource :   AuthConstraint (with members and groups)

1)
<qm-edit-access-control [resource]="_service.Resource" [originalResource]="_service.Original" (accessChanged)="ChangedTab = $event"></qm-edit-access-control>

	Where:
		- originalResource is a referece to the original resource (a deep-copy)
2)
<qm-edit-access-control [resource]="_service.Resource" [saveResult]="save_emitter" (accessChanged)="ChangedTab = $event"></qm-edit-access-control>

	private save_emitter: Subject<boolean> = new Subject();
	upon save:   save_emitter.next( true | false )

	Where:
		- saveResult is a Subject that alerts when the permissions have been updated to the current state.

*/

const ADDING_GROUP:number = 1;
const ADDING_MEMBER: number = 2;

@Component({
	selector: 'qm-edit-access-control',
	templateUrl: './edit-access-control.component.html',
	styleUrls: ['./edit-access-control.component.scss']
})
export class EditAccessControlComponent implements OnChanges, OnInit, OnDestroy{

	@Input() resource: AuthConstraint;
	@Input('saveResult') save_result: Subject<boolean>;
	@Input('originalResource') original_resource: AuthConstraint;
	@Input('roles') role_descriptions: Object[];
	@Output('accessChanged') access_changed = new EventEmitter<boolean>();

	private original_permissions : AuthConstraint;
	private permissions_changed: boolean;
	private groups: string[];
	set PermissionsChanged(changed: boolean){
		if (this.permissions_changed !== changed){
			this.permissions_changed = changed;
			this.access_changed.emit(changed);
		}
	}

	private editing_member: Object;

	private current_accessor_index: number  = -1;
	private form_state: number = -1;
	private groups_subscription: Subscription;


	private role: Object = {"role": "", "label": ""};

	set FormState(state: number){
		if (state == ADDING_MEMBER){
			this.editing_member = {
				"accessor": null,
				"label": this.role["label"],
				"role": this.role["role"],
				"can_read": true,
				"can_write": false
			};
		}else if (state == ADDING_GROUP){
			this.editing_member = {
				"name": "",
				"can_read": true,
				"can_write": false
			};
		}else {
			this.onCloseForm();
		}
		this.form_state = state;
	}

	private save_sub: Subscription;
	constructor(private session_data: SessionDataService) {}

	ngOnChanges(changes){
		if (changes['original_resource'] == undefined){
			this.original_permissions = this.resource.deepCopyPermissions();
		}else{
			this.original_permissions = this.original_resource;
			this.comparePermissions();
		}
	}

	ngOnInit(){
		this.role = this.role_descriptions[0];
		// when the project is saved or reverted, reset & use the current resources permissions as
		// the original_permissions.   only nessary if original_resource is not set.
		if (this.save_result && !this.original_resource){
			this.save_sub = this.save_result.subscribe(event =>{
				this.permissions_changed = false;
				if (this.original_resource == undefined){
					this.original_permissions = this.resource.deepCopyPermissions();
				}
				this.onCloseForm();
			});
		}
		this.groups_subscription = this.session_data.apiGet("/users/groups/all").subscribe(data =>{
			this.groups = data.result;
		});
	}


	canRead(accessor: Accessor){
		return accessor.scopes.includes("read");
	}
	canWrite(accessor: Accessor){
		return accessor.scopes.includes("write");
	}

	updateRole(role: Object){
		this.role = role;
		this.editing_member["role"] = role["role"];
		this.editing_member["label"] = role["label"];
	}

	editMemberAccess(member: Accessor, member_index: number){
		this.role = this.role_descriptions.find(role => role["role"] == member["role"]);
		this.current_accessor_index = member_index;
		this.editing_member = {
			"accessor": {"name": member.name, "id": member.id},
			"role": member.role,
			"label": member.label,
			"can_read": this.canRead(member),
			"can_write": this.canWrite(member)
		};
		this.form_state = ADDING_MEMBER;
	}

	editGroupAccess(group: Accessor, group_index: number){
		this.current_accessor_index = group_index;
		this.editing_member = {
			"name": group.name,
			"can_read": this.canRead(group),
			"can_write": this.canWrite(group)
		};
		this.form_state = ADDING_GROUP;
	}


	onSaveMember(){
		let new_member = {
			'name': this.editing_member['accessor']['name'],
			'id': this.editing_member['accessor']['id'],
			'scopes': ["read"],
			'label': this.editing_member['label'],
			'role': this.editing_member['role'],
		};
		if (this.editing_member['can_write']) new_member.scopes.push("write");
		if (this.current_accessor_index >= 0){
			this.resource.Members.splice(this.current_accessor_index, 1, new_member);
		}else{
			this.resource.Members.push(new_member);
		}
		this.comparePermissions();
		this.onCloseForm();
	}

	onSaveGroup(){
		let new_group = {
			'name': this.editing_member['name'],
			'scopes': ["read"]
		};
		console.log(this.editing_member);
		if (this.editing_member['can_write']) new_group.scopes.push("write");
		if (this.current_accessor_index >= 0){
			this.resource.Groups.splice(this.current_accessor_index, 1, new_group);
		}else{
			this.resource.Groups.push(new_group);
		}
		this.comparePermissions();
		this.onCloseForm()
	}


	revokeMemberAccess(member_index: number){
		this.resource.Members.splice(member_index, 1);
		this.comparePermissions();
	}
	revokeGroupAccess(group_index: number){
		this.resource.Groups.splice(group_index, 1);
		this.comparePermissions();
	}



	onCloseForm(){
		this.form_state = -1;
		this.editing_member = undefined;
		this.current_accessor_index = -1;
	}


	comparePermissions(){
		if (this.resource == undefined || this.original_permissions == undefined) return;
		// Check for extra or removed members
		if (this.original_permissions.Members.length !== this.resource.Members.length){
			this.PermissionsChanged = true;
			return;
		}
		// Check for extra or removed groups
		if (this.original_permissions.Groups.length !== this.resource.Groups.length){
			this.PermissionsChanged = true;
			return;
		}

		// check Members
		for (let original_member of this.original_permissions.Members){
			let found_match = false;
			for (let member of this.resource.Members){
				if (JSON.stringify(member) == JSON.stringify(original_member)){
					found_match = true;
					break;
				}
			}
			if (!found_match) {
				this.PermissionsChanged = true;
				return;
			}
		}

		// check Groups
		for (let original_member of this.original_permissions.Groups){
			let found_match = false;
			for (let member of this.resource.Groups){
				if (JSON.stringify(member) == JSON.stringify(original_member)){
					found_match = true;
					break;
				}
			}
			if (!found_match) {
				this.PermissionsChanged = true;
				return;
			}
		}
		this.PermissionsChanged = false;
	}

	ngOnDestroy(){
		if(this.save_sub) this.save_sub.unsubscribe();
		if (this.groups_subscription) this.groups_subscription.unsubscribe();
	}
}
