import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Request, Headers, RequestMethod, RequestOptions, ResponseContentType, URLSearchParams } from '@angular/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'app/shared/services/global.service';

import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as io from 'socket.io-client';

import { User, AuthConstraint } from 'app/shared/classes';
import { environment } from 'app/../environments/environment';
import { CustomHeader } from 'app/shared/interfaces/custom-header';
import { Reference } from 'app/shared/interfaces';

@Injectable()
export class SessionDataService {
	/* Http variables */
	private request;
	private headers: Headers;
	private custom_headers: string[]; // Store list of headers we've added so we can remove them
	private requestoptions: RequestOptions;
	private api_url = '';

	/* Sockets */
	private io; 	// Socket.io connection
	private sock_url = 'http://localhost';

	/* Confirmation Pop up */
	public confirm_emitter = new EventEmitter;
	private is_confirming: boolean = false;
	private confirmation_message: string = null;
	private confirm_message: string = null;
	private deny_message: string = null;
	private confirm_callback: () => void;
	private deny_callback: () => void;
	private users: User[];
	private profile_images: Object;
	private hide_nav: boolean = true;


	/* User Data */
	private expiry_warning: boolean = false;
	private expiry_count_down: any;
	private logout_count_down: any;
	private current_user: User;

	public notifications = [];

	public VERSION: boolean;
	public PRODUCTION: boolean;
	public ENV_NAME: string;

	// public refresh_interval: number = 15; // COMBAK User defined, global refresh interval?

	// undefined or true. determines if we have to reload app data (refresh occured)
	private app_initialized: boolean;

	private nav_state: string = "";
	SlackID: any;


	set Title(title: string) { this.title_service.setTitle(title); }
	get Title(): string { return this.title_service.getTitle(); }

	get SockUrl(): string { return this.sock_url; }
	get ApiUrl(): string { return this.api_url; }

	// Local/Session Storage. SessionStorage is cleared when browser closed.
	get ApiToken(): string { return sessionStorage.getItem("imp_token") || localStorage.getItem("api_token"); }
	set ApiToken(api_token: string) { localStorage.setItem("api_token", api_token); }
	set ImpToken(imp_token: string) { sessionStorage.setItem("imp_token", imp_token); }

	get Expiry(): string { return localStorage.getItem("expiry"); }
	set Expiry(expiry: string) { localStorage.setItem("expiry", expiry); }

	get ExpiryWarning(): boolean { return this.expiry_warning; }
	set ExpiryWarning(expiry_warning: boolean) { this.expiry_warning = expiry_warning; }

	// { '_id':  , '_source': [] }.  JSON representation of current_user.
	get UserData(): Object { return JSON.parse(sessionStorage.getItem("imp_user_data") || localStorage.getItem("user_data")); }
	set UserData(data: Object) { localStorage.setItem("user_data", JSON.stringify(data)); }

	set ImpUser(imp_user: Object) {
		if (imp_user == undefined) {
			sessionStorage.removeItem("imp_token");
			sessionStorage.removeItem("imp_user_data");
			this.current_user = User.dbImport(this.UserData);
		} else {
			// console.log("Imp User",imp_user);
			sessionStorage.setItem("imp_user_data", JSON.stringify(imp_user));
			this.current_user = User.dbImport(imp_user);
		}
	}

	get CurrentUser(): User { return this.current_user; }

	get IsConfirming(): boolean { return this.is_confirming; }
	set IsConfirming(is_confirming: boolean) {
		if (is_confirming == false) {
			// Clear callback functions when finished
			this.confirm_callback = null;
			this.deny_callback = null;
		}
		this.is_confirming = is_confirming;
	}


	// get image of x resolution.  images are fetched from slack during login.
	// stored in localStorage
	getProfileImage(resolution: number) {
		let image_urls = JSON.parse(localStorage.getItem("image_urls"));
		if (image_urls == undefined) return "";
		return image_urls['image_' + resolution];
	}
	set ProfileImages(image_urls: string[]) { localStorage.setItem("image_urls", JSON.stringify(image_urls)); }

	set RefreshInterval(refresh_interval: number) { localStorage.setItem("refresh_interval", String(refresh_interval)) }
	get RefreshInterval(): number { return Number(localStorage.getItem("refresh_interval")) || 15 }
	get RefreshIntervalMs(): number { return Number(localStorage.getItem("refresh_interval")) * 1000 || 15000 } // get Miliseconds for timeouts


	// controls the side navication bar
	get navState(): string { return this.nav_state; }
	set isPinned(isPinned: boolean) { localStorage.setItem("isPinned", isPinned.toString()); }
	get isPinned(): boolean { return JSON.parse(localStorage.getItem("isPinned")); }
	togglePinned() {
		// REVIEW: "Do it right"
		if (!this.IsConfirming) { // Don't allow togglePinned if loading or confirming
			this.isPinned = !this.isPinned;
			this.nav_state = (this.isPinned) ? "pinned" : "";
		}
	}
	openNav() { this.nav_state = "open"; }
	closeNav() { if (this.nav_state !== 'pinned') this.nav_state = ""; }
	get hideNav() { return this.hide_nav; }
	set hideNav(hide_nav: boolean) { this.hide_nav = hide_nav; }


	// does the token exist?
	get isAuthenticated() { return (Number(this.Expiry) >= Math.floor(Date.now() / 1000)); }
	get BasicAuth(): string {
		return (this.CurrentUser == null) ? null : btoa(this.CurrentUser.Email + ":" + this.ApiToken + ":" + this.UserData['slack_id']);
	}
	get AppInitialized(): boolean { return this.app_initialized; }

	get Router() { return this.router; }
	get Io() { return this.io; }

	constructor(private http: Http, private router: Router, private location: Location, private title_service: Title) {
		//, private global_service: GlobalService) {
		this.VERSION = environment.version;
		this.PRODUCTION = environment.production;
		this.ENV_NAME = environment.name;
		if (this.ENV_NAME == "QA") {
			// In QA environment, use the domain name the app is being loaded from
			this.api_url = environment.api_url;
			this.sock_url = environment.sock_url;
		} else {
			// In Prod this will be a fixed domain name. In dev it will be localhost:4280
			this.api_url = environment.api_url;
			this.sock_url = environment.sock_url;
		}
		this.api_url = this.api_url + '/api/v1'; //Append current API version

		// If we have a valid API token, reload app data   (page refreshed)
		// Otherwise, the app data will be loaded upon login.
		if (this.isAuthenticated && this.app_initialized == undefined) {
			let stored_user_data: Object = this.UserData;
			if (stored_user_data !== undefined && stored_user_data !== null) {
				this.current_user = User.dbImport(stored_user_data);
				this.reinitializeApp();
			} else {
				// Userdata is not set. Clear other local stored variables
				this.clearLoginData();
			}
		}

		// init nav-bar
		this.isPinned = JSON.parse(localStorage.getItem("isPinned")) || false;
		if (this.isPinned) this.nav_state = "pinned";

		/* Init http variables */
		this.headers = new Headers();
		this.headers.set("Content-Type", 'application/json');
		this.headers.append("Authorization", "Basic ");
		this.custom_headers = new Array<string>();
	}
	navigateBack() { this.location.back(); }
	getComponentPath(): string {
		return this.router.url.slice(this.router.url.lastIndexOf("/") + 1);
	}


	/* Http Methods */
	private addCustomHeaders(custom_headers: Array<CustomHeader>) {
		// console.log(custom_headers);
		this.clearCustomHeaders()
		for (let h of custom_headers) {
			this.custom_headers.push(h.key);
			this.setHeader(h.key, h.value);
		}
	}
	private setHeader(name: string, value: any) {
		this.headers.set(name, value);
	}
	// If we don't clear the custom headers, then all future requests will contain any and ALL headers we add
	private clearCustomHeaders() {
		for (let h of this.custom_headers) {
			this.headers.delete(h);
		}
	}

	private httpReq(url, options, custom_headers?: Array<CustomHeader>) {
		if (custom_headers) this.addCustomHeaders(custom_headers);
		options.url = url;
		options.headers = this.headers;
		this.requestoptions = new RequestOptions(options);
		return this.http.request(new Request(this.requestoptions)).pipe(
			map(this.extractData),
			catchError(this.handleError.bind(this))
		);
	}
	private extractData(res: Response) {
		return res.json() || {};
	}
	private handleError(error: Response | any) {
		return throwError(error);
	}

	/* For Basic HTTP requests */
	public get(url, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = { method: RequestMethod.Get };
		return this.httpReq(url, options, custom_headers);
	}
	public post(url, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		let options = {
			method: RequestMethod.Post,
			body: JSON.stringify(body)
		};
		return this.httpReq(url, options, custom_headers);
	}




	/* For API Requests. Use ApiUrl */
	public apiGet(uri, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = { method: RequestMethod.Get };
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiDelete(uri, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = { method: RequestMethod.Delete };
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiPost(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = {
			method: RequestMethod.Post,
			body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiPut(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = {
			method: RequestMethod.Put,
			body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}
	public apiPatch(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = {
			method: RequestMethod.Patch,
			body: JSON.stringify(body)
		};
		return this.httpReq(this.api_url + uri, options, custom_headers);
	}

	public apiDownload(uri, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		let options = { method: RequestMethod.Get };
		if (custom_headers) this.addCustomHeaders(custom_headers);
		this.requestoptions = new RequestOptions({
			responseType: ResponseContentType.Blob,
			method: RequestMethod.Get,
			url: this.api_url + uri,
			headers: this.headers
		});
		return this.http.request(new Request(this.requestoptions))
			.pipe(catchError(this.handleError.bind(this))
			);
	}

	public apiPostDownload(uri, body: Object, custom_headers?: Array<CustomHeader>): Observable<any> {
		this.setHeader("Authorization", "Basic " + this.BasicAuth);

		if (custom_headers) this.addCustomHeaders(custom_headers);
		this.requestoptions = new RequestOptions({
			responseType: ResponseContentType.Blob,
			method: RequestMethod.Post,
			url: this.api_url + uri,
			headers: this.headers,
			body: JSON.stringify(body)
		});
		return this.http.request(new Request(this.requestoptions))
			.pipe(catchError(this.handleError.bind(this))
			);
	}



	/* Socket Methods */

	subscribeToUserChannel() {
		if (this.CurrentUser) {
			/* Open socket for notifications */
			// console.log("Listening for Notifications",this.sock_url);
			this.observeChannel(this.CurrentUser.id()).subscribe(data => {
				console.log("Got Notification", data);
				this.pushNotificationTimeout(data['message'], data['type'], data['fa_icon'], data['fa_status']);
			});
		}
	}
	sendData(data: any, channel?: string) {
		let c = channel || this.CurrentUser.id();
		// console.log("Sending Message",c,data);
		this.io.emit(c, data);
	}

	observeChannel(channel: string) {
		return new Observable(observer => {
			// console.log("Connecting to",this.sock_url);
			this.io = io(this.sock_url);

			this.io.on(channel, data => {
				// console.log("Recieved data");
				observer.next(data);
			});

			return () => {
				// console.log("Disconnecting")
				this.io.disconnect();
			}
		});
	}




	// Utilities for components

	public isImpersonating(): boolean {
		if (sessionStorage.getItem("imp_user_data") == undefined && sessionStorage.getItem("imp_token") == undefined) {
			return false;
		} else {
			return true;
		}
	}

	public pushNotification(message: string, type: string, fa_icon?: string, fa_status?: string, action?: { text: string, callback: Function, displayCloseButton?: boolean }) {
		if (action && action.displayCloseButton == null) {
			action.displayCloseButton = true
		}
		this.notifications.push({
			'message': message,
			'type': type,
			'fa_icon': fa_icon || "",
			'fa_status': fa_status || "",
			'action': action
		});
	}
	public pushNotificationTimeout(message: string, type: string, fa_icon?: string, fa_status?: string, action?: { text: string, callback: Function }, duration?: number) {
		let id = Math.floor(Math.random() * 1000000000); // Generate
		this.notifications.push({
			'message': message,
			'type': type,
			'fa_icon': fa_icon || "",
			'fa_status': fa_status || "",
			'action': action,
			'id': id
		});
		setTimeout(() => {
			this.deleteNotification(id);
		}, duration || 5000);
	}
	private deleteNotification(id: number) {
		for (let i = 0; this.notifications.length > i; i++) {
			if (this.notifications[i].id == id) {
				this.notifications.splice(i, 1);
				break;
			}
		}
	}
	public clearNotification(index) {
		this.notifications.splice(index, 1);
	}


	public getUsersByRole(role?: string): Reference[] {
		if (this.users == undefined) return [];
		if (role == undefined) return this.users.map(user => { return { 'name': user.Name, 'id': user.id() } });
		return this.users.filter(user => user.Role == role).map(
			user => {
				return { 'name': user.Name, 'id': user.id() };
			}
		);
	}

	public getUsersByGroup(group?: string): Reference[] {
		if (this.users == undefined) return [];
		if (group == undefined) return this.users.map(user => { return { 'name': user.Name, 'id': user.id() } });
		return this.users.filter(user => user.Groups.indexOf(group) !== -1).map(
			user => {
				return { 'name': user.Name, 'id': user.id() };
			}
		);
	}


	// Use this custom header for GITLAB requests
	public getGitlabTokenHeader() {
		return { 'key': 'PRIVATE-TOKEN', 'value': this.CurrentUser.GitlabToken };
	}

	public apiErrorHandler = (error) => {
		console.error("apiError: ", error);
		let error_notification: any = {
			status_code: error.status,
			status_text: error.statusText,
			url: error.url
		};
		try {
			let body = JSON.parse(error._body);
			error_notification.message = body.result.message,
				error_notification.json = body.result.json
		} catch (e) {
			console.error("Error Response not JSON");
		}
		this.pushNotificationTimeout(error_notification, "Error", "far fa-exclamation-circle", "error");
	};

	public triggerWebhook(type: string, body: Object) {
		let url = `${environment.webhook_url}/api/v1/${type}/titan`;
		// console.log("Triggering webhook: " + url,body);
		this.setHeader("Authorization", "Basic " + this.BasicAuth);
		this.httpReq(url, { method: RequestMethod.Post, body: body }).subscribe(data => {
			// console.log("Sent error to webhook",data);
		}, e => {
			console.error("Error triggering webhook", e);
		});
	}

	public confirmationCheck(
		message?: string,
		confirm_message?: string,
		deny_message?: string
	): any {
		this.confirmation_message = message ? message : "Are you sure?";
		this.confirm_message = confirm_message ? confirm_message : "Yes";
		this.deny_message = deny_message ? deny_message : "No";
		this.IsConfirming = true;
		return new Observable(observer => {
			let sub = this.confirm_emitter.subscribe(data => {
				observer.next(data);
				observer.complete();
				sub.unsubscribe();
			});
		});
	}


	clearLoginData() {
		this.ApiToken = undefined;
		this.Expiry = "0";
		this.ProfileImages = undefined;
	}

	/* This will set Auth + User Data. Called from login component. */
	initializeApp(result: Object) {
		this.ApiToken = result['session']['api_token'];
		this.Expiry = result['session']['expiry'];
		this.ProfileImages = result['profile_images'];
		if (result['user'] !== undefined) {
			this.UserData = result['user'];
		} else {
			console.error("No user data in response");
			this.clearLoginData();
			return;
		}
		// stop impersonating after session has expired
		sessionStorage.removeItem("imp_token");
		sessionStorage.removeItem("imp_user_data");
		this.current_user = User.dbImport(result['user']);

		// load all app data. :: redirect = true (redirect to home page from login page)
		this.hideNav = false;
		// redirect after loading app-data.
		let sub = this.reinitializeApp().subscribe(
			data => { },
			this.apiErrorHandler,
			() => {
				let login_url = localStorage.getItem("login_url") || "/";
				localStorage.removeItem("login_url");
				if (this.current_user.Role == "Sales") this.router.navigate(['/sales/bids']);
				else this.router.navigateByUrl(login_url || "/");
				sub.unsubscribe();
			}
		);
	}

	reinitializeApp(): Observable<boolean> {
		return Observable.create((observer: Observer<boolean>) => {
			let reinit_sub = this.apiGet('/users?limit=250&fields=name,id,role,groups&sort=name:asc').subscribe(
				data => {
					this.users = data['result'].map(user => User.dbImport(user));
					this.app_initialized = true;
					this.getExpiryCountDown();
				},
				error => observer.error(false),
				() => {
					this.app_initialized = true;
					this.hideNav = false;
					observer.next(true);
					observer.complete();
					reinit_sub.unsubscribe();
				}
			);
		});
	}

	getExpiryCountDown() {
		if (this.expiry_count_down) clearTimeout(this.expiry_count_down);
		this.expiry_warning = false;

		let one_hour_before_expiry_ms = ((parseInt(this.Expiry) * 1000) - (3600 * 1000));
		let expiry_count_down_ms = one_hour_before_expiry_ms - Date.now();
		if (expiry_count_down_ms < 0) expiry_count_down_ms = 0;

		// console.log("Expiry Count Down",expiry_count_down_ms);
		this.expiry_count_down = setTimeout(() => {
			// console.log("Session Expiring Soon");
			if (this.expiry_warning !== true) {
				this.expiry_warning = true;
				let expiry_time = new Date(parseInt(this.Expiry) * 1000);
				this.pushNotification(
					`Your session is going to expire at ${expiry_time.getHours()}:${expiry_time.getMinutes()}`,
					'session_timeout',
					'fas fa-exclamation-triangle',
					'warn',
					{
						"text": "Keep me logged in",
						"callback": () => {
							this.apiGet('/renew/login').subscribe(
								data => {
									// console.log("Result",data);
									if (data.result.success === true && data.result.new_expiry > this.Expiry) {
										// console.log("Setting Expiry",data);
										this.Expiry = data.result.new_expiry;
									}
									this.getExpiryCountDown();
								},
								this.apiErrorHandler
							);
						}
					}
				)
			}
			this.getLogoutCountdown();
		}, expiry_count_down_ms);
		if (this.logout_count_down) clearTimeout(this.logout_count_down);
	}

	getLogoutCountdown() {
		if (this.logout_count_down) clearTimeout(this.logout_count_down);

		let logout_count_down_ms = (parseInt(this.Expiry) * 1000) - Date.now();
		if (logout_count_down_ms < 0) logout_count_down_ms = 0;

		// console.log("Expiry Count Down",logout_count_down_ms);
		this.logout_count_down = setTimeout(() => {
			this.logOut();
		}, logout_count_down_ms);
	}

	saveCurrentUserData() {
		let sub = this.apiPut(`/users/${this.CurrentUser.id()}`, this.CurrentUser).subscribe(
			data => { },
			this.apiErrorHandler,
			() => sub.unsubscribe()
		);
	}

	createGitlabAccount(): Observable<boolean> {
		// console.log("Initializing Gitlab account...");
		if (this.CurrentUser) {
			return new Observable<boolean>(observer => {
				let body = {
					"email": this.CurrentUser.Email,
					"username": this.CurrentUser.Username,
					"name": this.CurrentUser.Name,
					"confirm": false,
					"reset_password": true,
					"external": true,
					"organization": "Quest Mindshare"
				};
				// console.log("Gitlab account Using",body);
				let sub = this.apiPost('/gitlab/create-user', body).subscribe(
					data => {
						// console.log("Created gitlab user",data);
						if (this.isImpersonating()) {
							let updatedImpUser = Object.assign({}, this.UserData);
							updatedImpUser['gitlab_token'] = data.result.api_token;
							this.ImpUser = updatedImpUser;
						} else {
							this.CurrentUser.GitlabToken = data.result.api_token;
						}

						this.saveCurrentUserData();
						observer.next(true);
						observer.complete();
						sub.unsubscribe();
					},
					this.apiErrorHandler
				);
			});
		} else {
			console.error("CurrentUser not available!");
		}
	}

	public UserInGroups(groups: string[]): boolean {
		if (!this.isAuthenticated || this.CurrentUser.Groups == undefined) {
			return false;
		}

		let num_excluded_groups: number = 0;
		// !india_op would restrict india_ops
		for (let group of groups) {
			if (group.charAt(0) == "!") {
				num_excluded_groups++;
				if (this.CurrentUser.Groups.indexOf(group.slice(1)) >= 0) {
					return false;
				}
			}
		}

		if (num_excluded_groups == groups.length) {
			return true;
		}

		// allow only if in groups
		for (let group of groups) {
			if (this.CurrentUser.Groups.indexOf(group) >= 0) {
				return true;
			}
		}
		return false;
	}

	public UserInRole(role: string): boolean {
		if (!this.isAuthenticated || this.CurrentUser.Role == undefined) {
			return false;
		}

		return this.CurrentUser.Role == role;
	}

	logOut() {
		localStorage.removeItem("expiry");
		localStorage.removeItem("api_token");
		localStorage.removeItem("user_data");
		this.hideNav = true;
		this.Router.navigate(['/login']);
	}
}
