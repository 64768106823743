import { Qualification } from 'app/shared/interfaces';

export class Question {

	public id: Function;
	private text: Object;
	private field: string;
	private panels: Object;
	private category: string;
	private key: string;
  private type: string;
  private api_ref_id: number;

	constructor(_id: string){
		this.id = () => _id;
	}

	get Text() : Object { return this.text; }
	get Field() : string { return this.field; }
	get Panels() : Object { return this.panels; }
	get Key() : string { return this.key; }
	get Category() : string { return this.category; }
  get Type() : string { return this.type; }
  get API_ref_id() : number { return this.api_ref_id; }

	set Text(text: Object) { this.text = text; }
	set Field(field: string) { this.field = field; }
	set Panels(panels: Object) { this.panels = panels; }
	set Key(key: string) { this.key = key; }
	set Category(category: string) { this.category = category; }
  set Type(type: string) { this.type = type; }
  set API_ref_id(ref_id: number){ this.api_ref_id = ref_id; }




	asQualification(field? :string) : Qualification{
		let q = {
			"field": field || this.field,
			"minimum_should_match": 1,
			"question_type": this.type,
			"question_id": this.id(),
			"question_key": this.key,
      "question_text": this.text["en"],
      "API_ref_id": this.api_ref_id,
		};
		console.log("Returning Qualification",q);
		return q;
	}


	static dbImport(source: Object) : Question{
		let panel = new Question(source['_id']);
		for (let property of Object.keys(source)){
			if(property == '_id' || property == 'answers') continue;
			panel[property] = source[property];
		}
		return panel;
	}

}
