export const CHART = {
	'COLORS': [
		{
			'defaultFontColor':"#F0F0F0",
			'borderColor':"#F0F0F0", // Match with $background
			'backgroundColor':[
				"#222222",
				"#f05425",
				"#f08b6d",
				"#3cbd70",
				"#039be5",
				"#24265f",
				"#ff7676",
				"#47b9ff",
				"#26fd56",
				"#00bf2b",
				"#d31a48",
				"#CD8C95",
				"#FF3E96",
				"#C67171",
				"#3498db",
				"#1abc9c",
				"#2ecc71",
				"#34495e",
				"#8e44ad",
				"#e74c3c",
				"#2c3e50",
				"#e67e22",
				"#f1c40f",
				"#c0392b",
				"#d35400",
				"#7f8c8d",
				"#f39c12",
				"#f08b6d", // Repeats
				"#3cbd70",
				"#039be5",
				"#24265f",
				"#ff7676",
				"#47b9ff",
				"#26fd56",
				"#00bf2b",
				"#d31a48",
				"#CD8C95",
				"#FF3E96",
				"#C67171",
				"#3498db",
				"#1abc9c",
				"#2ecc71",
				"#34495e",
				"#8e44ad",
				"#e74c3c",
				"#2c3e50",
				"#e67e22",
				"#f1c40f",
				"#c0392b",
				"#d35400",
				"#7f8c8d",
				"#f39c12",
				"#f08b6d", // Repeats
				"#3cbd70",
				"#039be5",
				"#24265f",
				"#ff7676",
				"#47b9ff",
				"#26fd56",
				"#00bf2b",
				"#d31a48",
				"#CD8C95",
				"#FF3E96",
				"#C67171",
				"#3498db",
				"#1abc9c",
				"#2ecc71",
				"#34495e",
				"#8e44ad",
				"#e74c3c",
				"#2c3e50",
				"#e67e22",
				"#f1c40f",
				"#c0392b",
				"#d35400",
				"#7f8c8d",
				"#f39c12"
			]
		}
	],
	'OPTIONS':{
		reponsive: true,
		maintainAspectRatio: false
	}
};
