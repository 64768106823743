import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { Route, RouterModule } from '@angular/router';

import { AdaptableTextareaComponent } from 'app/prescreener/question-editor/adaptable-textarea/adaptable-textarea.component';


// Node Modules -- application scope.  very likely to be used in all modules.
import { NguiAutoCompleteModule } from 'veden-auto-complete';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
// import { MyDatePickerModule } from 'mydatepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

// custom components -- application scope
import { SearchBar } from 'app/shared/components/filterbar/searchbar.component';
import { NotesComponent } from 'app/shared/components/notes/notes.component';
import { LoadingIconComponent } from 'app/shared/components/loading-icon/loading-icon.component';
import { HideOnMouseLeaveDirective } from 'app/shared/directives/hide-on-mouse-leave.directive';
import { RefreshSelectorComponent } from 'app/shared/components/refresh-selector/refresh-selector.component';
import { CopyToClipboard } from 'app/shared/components/copy-to-clipboard/copy-to-clipboard.component';
import { ClipboardDirective } from 'app/shared/directives/clipboard.directive';
import { ClientProjectItem } from "app/sales/clients/view-client/list-client-projects/client-project-item.component";
import { DateRangePicker } from 'app/shared/components/daterangepicker/daterangepicker.component'
import { PayoutMethodIconComponent } from 'app/shared/components/icons/payout-method-icon/payout-method-icon.component';
import { PayoutStatusIconComponent } from 'app/shared/components/icons/payout-status-icon/payout-status-icon.component';

// Shared Components
import { EditAccessControlComponent } from 'app/shared/components/edit-access-control/edit-access-control.component';

import { ListProjectsComponent } from 'app/projects/list-projects/list-projects.component';
import { ProjectListItemComponent } from 'app/projects/list-projects/project-list-item.component';


import { vEdenVirtualScrollComponent } from 'app/shared/components/virtual-scroll.component';

import { AuthGaurd } from 'app/navigation/auth-gaurd.service';
import { ProgressCircleComponent } from 'app/shared/components/progress-circle/progress-circle.component';

import { TitleizePipe } from 'app/shared/pipes/titleize.pipe';

import { RegexPipe } from 'app/shared/pipes/regex-search.pipe';
import { JsonDisplayComponent } from './components/json-display/json-display.component';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		FileUploadModule,
		NguiAutoCompleteModule,
		CustomFormsModule,
		ChartsModule,
		// MyDatePickerModule,
		NgxDaterangepickerMd.forRoot(),
		RouterModule
	],
	declarations: [
		SearchBar,
		NotesComponent,
		LoadingIconComponent,
		HideOnMouseLeaveDirective,
		RefreshSelectorComponent,
		EditAccessControlComponent,
		CopyToClipboard,
		ProgressCircleComponent,
		ProjectListItemComponent,
		ListProjectsComponent,
		ClipboardDirective,
		AdaptableTextareaComponent,
		vEdenVirtualScrollComponent,
		ClientProjectItem,
		TitleizePipe,
		RegexPipe,
		JsonDisplayComponent,
		DateRangePicker,
		PayoutMethodIconComponent,
		PayoutStatusIconComponent
	],
	exports: [
		SearchBar,
		NotesComponent,
		HideOnMouseLeaveDirective,
		NguiAutoCompleteModule,
		FileUploadModule,
		ChartsModule,
		ReactiveFormsModule,
		CustomFormsModule,
		LoadingIconComponent,
		RefreshSelectorComponent,
		EditAccessControlComponent,
		ClientProjectItem,
		FormsModule,
		// MyDatePickerModule,
		JsonDisplayComponent,
		CopyToClipboard,
		ProgressCircleComponent,
		ListProjectsComponent,
		ProjectListItemComponent,
		vEdenVirtualScrollComponent,
		ClipboardDirective,
		AdaptableTextareaComponent,
		TitleizePipe,
		RegexPipe,
		DateRangePicker,
		PayoutMethodIconComponent,
		PayoutStatusIconComponent
	],
	providers: []
})
export class SharedModule { }
