import { Directive, Output, EventEmitter, Input, HostListener } from '@angular/core';


@Directive({
  selector: '[hide-on-mouseleave]'
})
export class HideOnMouseLeaveDirective {

	private timeout: any;
	@Output() closeme = new EventEmitter();
	@Input('timeoutInterval') timeout_interval: number = 100;

	constructor() {
		this.timeout = setTimeout( ()=>{ this.closeme.emit(); }, this.timeout_interval);
	}


	@HostListener('mouseenter')
		questionHoveredUpon(){
			if (this.timeout !== undefined) clearTimeout(this.timeout);
		}

	@HostListener('mouseleave')
		questionLeft(){
			this.timeout = setTimeout(()=>{ this.closeme.emit(); }, this.timeout_interval);
		}



}
