// Angular Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpModule, JsonpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Root/ App Component
import { AppComponent } from 'app/app.component';
// Setup Routing
import { AppRoutingModule } from 'app/app-routing.module';


// Import application-scope components (search bar, auto-complete, etc)
// multiple instances of these components are used
import { MaterialModule } from './material.module';
import { SharedModule } from 'app/shared/shared.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule  } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/app.reducers';
import { AppEffects } from 'app/store/app.effects';

import { NotificationsComponent } from 'app/shared/components/notifications/notifications.component';
import { ConfirmationDialog } from 'app/shared/components/confirmation/confirmation.component';

/* Components */
import { PortalHomeComponent } from 'app/portal-home/portal-home.component';
import { ReportBugComponent } from 'app/report-bug/report-bug.component';
import { UserInfoComponent } from 'app/navigation/user-info/user-info.component';
import { SocketTestingComponent } from './socket-testing/socket-testing.component';
import { NavigationBarComponent, HeaderComponent, AuthGaurd, LoginComponent, LoginAlternativeComponent } from 'app/navigation';
import { ErrorPageComponent } from 'app/navigation/error-page/error-page.component';
import { ProjectsSummaryComponent } from './portal-home/projects-summary/projects-summary.component';



// Services
import { SessionDataService } from 'app/shared/services';
import { VersionCheckService } from 'app/shared/services';
import { GroupGaurd } from 'app/shared/services/group-gaurd.service';
import { GlobalService } from 'app/shared/services/global.service';
import { DeactivateGuard } from 'app/shared/services/deactivate-gaurd.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler.service';

import { environment } from 'app/../environments/environment';
import { CustomSerializer } from 'app/store/custom-serializer';
import { UserService } from './shared/services/user.service';
import { ReferralCampaignService } from './shared/services/referral-campaign.service';

@NgModule({
	declarations: [
		AppComponent,
		PortalHomeComponent,
		LoginComponent,
		LoginAlternativeComponent,
		NavigationBarComponent,
		HeaderComponent,
		ConfirmationDialog,
		UserInfoComponent,
		ReportBugComponent,
		NotificationsComponent,
		SocketTestingComponent,
		ErrorPageComponent,
		ProjectsSummaryComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MaterialModule,
		HttpModule,
		JsonpModule,
		SharedModule,
		StoreModule.forRoot(reducers),
		EffectsModule.forRoot(AppEffects),
		StoreRouterConnectingModule.forRoot({stateKey: 'router_state'}),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		AppRoutingModule // must be last
	],
	providers: [
		SessionDataService,
		VersionCheckService,
		UserService,
		ReferralCampaignService,
		GlobalService,
		AuthGaurd,
		GroupGaurd,
		DeactivateGuard,
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService
		},{
			provide: RouterStateSerializer,
			useClass: CustomSerializer
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
