import { Injectable, OnDestroy } from '@angular/core';
import { SessionDataService } from 'app/shared/services/session-data.service';

import { TabParentService } from 'app/shared/services/tab-parent.service';
import { Project, AuthConstraint, Contact, Client, Quota } from 'app/shared/classes';
import { Qualification } from 'app/shared/interfaces';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class ProjectService extends TabParentService implements OnDestroy {


	get Original() : Project { return <Project> this.OriginalResource; }
	set Original(project : Project) { this.OriginalResource = <AuthConstraint> project; }

	get Project() : Project { return <Project> this.working_copy; }
	set Project(project: Project) { this.working_copy = <AuthConstraint> project; }

	private current_qualifiction: Qualification;
	get CurrentQualification() { return this.current_qualifiction; }
	set CurrentQualification(qualification: Qualification) { this.current_qualifiction = qualification; }

	private current_quota: Quota;
	get CurrentQuota() : Quota { return this.current_quota; }
	set CurrentQuota(quota: Quota) { this.current_quota = quota; }

	private contacts: {"selected": boolean, "contact": Contact}[];
	private client: Client;
	private expose_costs: boolean;

	get Client() : Client { return this.client; }
	set Client(client: Client) { this.client = client; }

	get ProjectReadonly() { return this.Project.IsClosed; }

	get Contacts() : {"selected": boolean, "contact": Contact}[] { return this.contacts; }
	set Contacts(contacts: {"selected": boolean, "contact": Contact}[]) { this.contacts = contacts; }

	protected dbImport: Function = Project.dbImport;

	constructor(session_data: SessionDataService, private route: ActivatedRoute){
		super(session_data);
		this.Original = this.route.snapshot.data["project"];
	}

	ngOnDestroy(){
		this.Client = undefined;
		this.Contacts = undefined;
		this.OriginalResource = undefined;
		this.Project = undefined;
		this.expose_costs = undefined;
	}

}
