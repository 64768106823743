// Auth constraint must be imported directly from this file (not the index)
// Reference: https://github.com/angular/angular-cli/issues/5769#issuecomment-298079583
import { AuthConstraint } from 'app/shared/classes/auth-constraint';


import { PartnerQuota } from 'app/shared/classes/quota';
import { Question } from 'app/shared/classes/question';
import { Reference, Accessor, PartnerQualifications, Qualification,  Cost, Panel, PII, Participation } from 'app/shared/interfaces';

interface Partner {
	"rl": number,
	"name": string
}


export class Project extends AuthConstraint {

	// basic properties
	private date_created: Date;
	private created_by: Reference;
	private name : string;
	private number: string;
	private status : string;
	private study_type : string;
	private sampling_method : string;
	private length_of_interview : number;
	private overall_target: number;
	private is_wholesale : boolean = false; // default
	private wave: number = 1;
	private panelist_description: string;
	private timeframe: string;
	private is_closed: boolean = false; // default
	private date_closed : Date;
	private api_generated: boolean;
	private is_pantheon_project: boolean;
	// programming properties
	private programming_requirements: string[] = [];
	private custom_programming: string = "";
	private host_survey: boolean = false;
	// sampling properties:
	private panels: Panel[] = [];
	private partners: Partner[] = [];
	private quotas: PartnerQuota[] = [];
	private past_participation: Participation[] = [];
	private qualifications: PartnerQualifications[] = [];
	private partner_variables: {"partner_rl": number, "variables": Object}[] = [];
	// billing info
	private client : Reference;
	private contacts: Reference[] = [];
	private po_number: string;
	private currency: string = "USD";
	private invoicing_status: string;
	private sign_off_member: Reference;
	private date_invoicing_submitted: Date;
	private bid_origin: string;
	// financials
	private quote: Cost[] = [];
	private invoice_items: Cost[] = [];
	private _quoteTotal: number;
	public get quoteTotal(): number {
		return this._quoteTotal;
	}
	public set quoteTotal(value: number) {
		this._quoteTotal = value;
	}
	private _invoiceTotal: number;
	public get invoiceTotal(): number {
		return this._invoiceTotal;
	}
	public set invoiceTotal(value: number) {
		this._invoiceTotal = value;
	}
	private expenses: Cost[] = [];


	private feasibility : boolean;

	private default_prescreener_id: string;
	private default_entry_url: string;
	private default_payout: number;
	private auto_invite_batch_size: number;
	private auto_invite_interval: number;
	private auto_invite: boolean;

	private closeout: Object = {
		"gdpr_removed": false,
		"invoicing_submitted": false,
		"final_ids_uploaded": false,
		"costs_reconciled": false,
		"panelists_paid": false
	};

	private pii: PII = {
		"quest_survey": false,
		"quest_detect": false,
		"client_survey": false
	};



	get DateCreated(): Date { return this.date_created; }
	get CreatedBy() : Reference { return this.created_by; }
	set CreatedBy(user: Reference) { this.created_by = user}
	get Name() { return this.name; }
	set Name(name: string) { this.name = name; }
	get Number(): string { return this.number; }
	set Number(number: string) { this.number = number; }
	get Status() { return this.status; }
	set Status(status: string) { this.status = status; }
	get StudyType() { return this.study_type; }
	set StudyType(type: string) { this.study_type = type; }
	get SamplingMethod() { return this.sampling_method; }
	set SamplingMethod(method: string) { this.sampling_method = method; }
	get LengthOfInterview() { return this.length_of_interview; }
	set LengthOfInterview(loi: number){ this.length_of_interview = loi; }
	get BidTarget() { return this.quote.map(item => item.quantity).reduce((qty, sum) => sum+qty, 0); }
	get OverallTarget() :number { return this.overall_target; }
	set OverallTarget(target: number) { this.overall_target = target; }
	get IsWholesale() : boolean { return this.is_wholesale || false; }
	set IsWholesale(wholesale: boolean) { this.is_wholesale = wholesale; }
	get Wave() : number { return this.wave; }
	set Wave(wave_no: number) { this.wave = wave_no; }
	get PanelistDescription() : string {return this.panelist_description; }
	set PanelistDescription(description: string) { this.panelist_description = description; }
	get Timeframe() : string { return this.timeframe; }
	set Timeframe(timeframe: string) { this.timeframe = timeframe; }
	get IsClosed() : boolean { return this.is_closed; }
	set IsClosed(close: boolean) { this.is_closed = close; }
	get ApiGenerated(): boolean { return this.api_generated; }
	get IsPantheonProject(): boolean { return this.is_pantheon_project; }
	// programming properties
	get ProgrammingRequirements() : string[] { return this.programming_requirements; }
	set ProgrammingRequirements(reqts: string[]) {
		this.programming_requirements = reqts;
		this.host_survey = this.programming_requirements.indexOf("Survey") !== -1;
	}
	get CustomProgramming() { return this.custom_programming; }
	set CustomProgramming(programming: string) {this.custom_programming = programming; }
	get HostSurvey() { return this.host_survey; }
	get ProgrammingSource() { return (this.host_survey) ? "Quest" : "Client"; }
	// sampling properties
	get Panels(): Panel[] { return this.panels; }
	set Panels(panels: Panel[]) { this.panels = panels; }
	get Partners(): Partner[] { return this.partners; }
	set Partners(partners: Partner[]) { this.partners = partners; }
	get PastParticipation() : Participation[] { return this.past_participation; }
	set PastParticipation(participation: Participation[]) { this.past_participation = participation; }
	get Qualifications() : PartnerQualifications[] { return this.qualifications; }
	set Qualifications(qualifications: PartnerQualifications[]) { this.qualifications = qualifications;}
	get Quotas(): PartnerQuota[] { return this.quotas; }
	set Quotas(quotas: PartnerQuota[]){ this.quotas = quotas; }
	get PartnerVariables() : {"partner_rl": number, "variables": Object}[] { return this.partner_variables; }
	set PartnerVariables(variables: {"partner_rl": number, "variables": Object}[]) { this.partner_variables = variables; }
	// billing properties
	get Client() { return this.client; }
	set Client(client: Reference) { this.client = client; }
	get Contacts() : Reference[] { return this.contacts; }
	set Contacts(contacts: Reference[]) { this.contacts = contacts; }
	get POnumber() : string { return this.po_number; }
	set POnumber(po: string) { this.po_number = po; }
	get Currency() : string { return this.currency; }
	set Currency(currency: string) { this.currency = currency; }
	get InvoicingStatus() { return this.invoicing_status; }
	set InvoicingStatus(status: string) { this.invoicing_status = status; }
	get SignOffMember() : Reference { return this.sign_off_member; }
	set SignOffMember(member: Reference) { this.sign_off_member = member; }
	get DateInvoicingSubmitted() : Date { return this.date_invoicing_submitted; }
	set DateInvoicingSubmitted(date: Date) { this.date_invoicing_submitted = date; }
	get BidOrigin() : string { return this.bid_origin; }
	// financials
	get Quote() { return this.quote; }
	set Quote(items: Cost[]) { this.quote = items; }
	get InvoiceItems() { return this.invoice_items; }
	set InvoiceItems(items: Cost[]) { this.invoice_items = items; }
	get Expenses() : Cost[] { return this.expenses; }
	set Expenses(expenses : Cost[]) { this.expenses = expenses; }
	// Are third party panels included flag


	get ProjectManager() : Accessor {
		let i = this.findMemberByRole("project_manager");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set ProjectManager(member: Accessor) {
		if(member !== null && member !== undefined){
			member = {
				"name": member.name,
				"id": member.id,
				"label": "Project Manager",
				"role": "project_manager",
				"scopes": ["read", "write"]
			}
		}
		let i = this.findMemberByRole("project_manager");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}
	get BackupPM() : Accessor {
		let i = this.findMemberByRole("backup_pm");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set BackupPM(member: Accessor) {
		if(member !== null && member !== undefined){
			member = {
				"name": member.name,
				"id": member.id,
				"label": "Backup PM",
				"role": "backup_pm",
				"scopes": ["read", "write"]
			}
		}
		let i = this.findMemberByRole("backup_pm");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}
	get SalesRep() : Accessor {
		let i = this.findMemberByRole("sales_rep");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set SalesRep(member: Accessor) {
		if(member !== null && member !== undefined){
			member = {
				"name": member.name,
				"id": member.id,
				"label": "Sales Representative",
				"role": "sales_rep",
				"scopes": ["read", "write"]
			}
		}
		let i = this.findMemberByRole("sales_rep");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}
	get Programmer() : Accessor {
		let i = this.findMemberByRole("programmer");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set Programmer(member: Accessor) {
		if(member !== null && member !== undefined){
			member = {
				"id": member.id,
				"name": member.name,
				"label": "Programmer",
				"role": "programmer",
				"scopes": ["read", "write"]
			}
		}
		let i = this.findMemberByRole("programmer");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}

	get PII() : PII { return this.pii; }
	set PII(pii: PII) { this.pii = pii; }

	get Feasibility(): boolean { return this.feasibility; }
	set Feasibility(feasibility: boolean) { this.feasibility = feasibility; }

	get Closeout() : Object { return this.closeout; }
	set Closeout(closure: Object) { this.closeout = closure; }

	get DefaultPrescreenerId() : string { return this.default_prescreener_id; }
	set DefaultPrescreenerId(default_prescreener_id : string) { this.default_prescreener_id = default_prescreener_id; }

	get DefaultEntryUrl() : string { return this.default_entry_url; }
	set DefaultEntryUrl(default_entry_url : string) { this.default_entry_url = default_entry_url; }

	get DefaultPayout() : number { return this.default_payout; }
	set DefaultPayout(default_payout : number) { this.default_payout = default_payout; }

	get AutoInvite() : boolean { return this.auto_invite; }
	set AutoInvite(auto_invite : boolean) { this.auto_invite = auto_invite; }

	get AutoInviteInterval() : number { return this.auto_invite_interval; }
	set AutoInviteInterval(auto_invite_interval : number) { this.auto_invite_interval = auto_invite_interval; }

	get AutoInviteBatchSize() : number { return this.auto_invite_batch_size; }
	set AutoInviteBatchSize(auto_invite_batch_size : number) { this.auto_invite_batch_size = auto_invite_batch_size; }

	constructor(_id?: string){
		super(_id);
		this.members = [];
	}

	public getOpinionChampPanelsIds() : string[] {
		return this.Panels.filter(panel=>panel.partner_rl == 200).map(panel=>panel.panel_id);
	}

	public toReference() {
		return {
			"id": this.id(),
			"name": this.name
		}
	}


	static dbImport(source: Object) : Project{
		let new_project = new Project(source['_id']);
		for (let property of Object.keys(source)){
			if (property == "_id") continue;
			new_project[property] = source[property];
		}
		return new_project;
	}

	public setDefaultMembers(){
		let add_created_by = true;
		for (let member of this.members)
			if (member.id == this.created_by.id){
				add_created_by = false;
				break;
			}
		if (add_created_by && this.created_by){
			this.members.push({
				"name": this.created_by.name,
				"id": this.created_by.id,
				"label": "Created By",
				"scopes": ["read", "write"],
				"role": "other"
			});
		}

		this.groups = [{
			"name": "sales_admins",
			"scopes": ["read", "write"]
		},{
			"name": "finance_admins",
			"scopes": ["read", "write"]
		},{
			"name": "project_manager_admins",
			"scopes": ["read", "write"]
		}];
	}

	/*
	* Returns qualifications which filter for panelist settings.
	* ie. Study Type, Min/Max survey length
	*/
	private getSettingQualifications(): Qualification[]{
		let qualifications = <Qualification[]> [
			{
				"field": "settings.survey_length.gte",
				"question_type": "number",
				"question_text": "Minimum Survey Length",
				"answer_ranges": [
					{
						"from": 0,
						"to": this.LengthOfInterview,
						"text": `Minimum Survey Length ${this.LengthOfInterview} Minutes`,
						"operator": "must"
					}
				],
				"operator": "must"
			},
			{
				"field": "settings.survey_length.lte",
				"question_type": "number",
				"question_text": "Maximum Survey Length",
				"answer_ranges": [
					{
						"from": this.LengthOfInterview,
						"to": 1440,
						"text": `Maximum Survey Length ${this.LengthOfInterview} Minutes`,
						"operator": "must"
					}
				],
				"operator": "must"
			}
		];
		if(this.StudyType){
			qualifications.push(<Qualification>{
				"field": "settings.survey_types",
				"question_type": "internal",
				"question_text": `Participates in ${this.StudyType} surveys.`,
				"answers": [
					{
						"id": this.StudyType,
						"operator": "must"
					}
				],
				"operator": "must"
			});
		}
		return <Qualification[]> qualifications;
  }

  private getOpinionChampQualifications(qualifications: PartnerQualifications[]):Qualification[] {
    const ocQual = qualifications.find(q => q.partner_rl === 200);

    if (ocQual && ocQual.qualifications) {
      return ocQual.qualifications;
    }

    return [];
  }

	public getProjectQuery(bypassParticipations = false): {qualifications:Qualification[], past_participation: Participation[]} {

		if(this.feasibility === true){
			return {
				qualifications: this.getOpinionChampQualifications(this.qualifications),
				past_participation:[]
			};
		}else{
			return {
				qualifications:[...this.getOpinionChampQualifications(this.qualifications),...this.getSettingQualifications()],
				past_participation: bypassParticipations ? [] : this.PastParticipation
			};
		}
	}

}
