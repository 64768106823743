import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionDataService } from 'app/shared/services';
import { CHART } from 'app/shared/globals/chart-options';
import { Subscription } from 'rxjs/Subscription';


@Component({
	selector: 'qm-projects-summary',
	templateUrl: './projects-summary.component.html',
	styleUrls: ['./projects-summary.component.scss']
})
export class ProjectsSummaryComponent implements OnInit, OnDestroy {

	private data_loaded:boolean = false;

	private data: any = new Array<{'data':number[], label: string}>();
	private chart_data: number[][] = [];
	private chart_labels: string[] = [];
	private chart_colors;
	private chart_options = {
		reponsive: true,
		maintainAspectRatio: false,
		legend: {
			display: true
		}
	};

	private get_status_sub: Subscription;

	set Days(days: number){ localStorage.setItem("status_days",`${days}`); }
	get Days(): number{ return parseInt(localStorage.getItem("status_days")) || 90; }

	constructor(private session_data: SessionDataService) { }

	ngOnInit() {
		this.chart_colors = CHART.COLORS;
		this.chart_options.legend['position'] = 'bottom';
		this.chart_options['scales'] = {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {min: 0}
				}
			]
		};
		this.loadStatusSummary();
	}

	loadStatusSummary(){
		this.data_loaded = false;
		this.get_status_sub = this.session_data.apiGet(`/projects/status?days=${this.Days}`).subscribe(
			data => {
				this.chart_labels = ["Project Status"];
				this.chart_data = [];

				for(let i in data.result){
					this.chart_data[i] = {
						data:[data.result[i].count],
						label: data.result[i].status,
						backgroundColor: [this.chart_colors[0].backgroundColor[i]]
					};
				}
				this.chart_data = this.chart_data.slice();
			},
			this.session_data.apiErrorHandler,
			() => {
				this.data_loaded = true;
			}
		);
	}

	ngOnDestroy(){
		if (this.get_status_sub) this.get_status_sub.unsubscribe();
	}
}
