import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionDataService } from 'app/shared/services';

@Component({
  selector: 'qm-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationDialog implements OnInit {

	@Input() private message: string;
	@Input() private confirm_message: string = "Yes";
	@Input() private deny_message: string = "No";


  constructor(private session_data: SessionDataService) { }

  ngOnInit() {
  }

	runConfirm(){
		this.session_data.confirm_emitter.emit(true);
    this.session_data.IsConfirming = false;
	}

	runDeny(){
		this.session_data.confirm_emitter.emit(false);
    this.session_data.IsConfirming = false;
	}

}
