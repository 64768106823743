import { Reference } from 'app/shared/interfaces';
import { AuthConstraint } from 'app/shared/classes/auth-constraint';


export class Contact extends AuthConstraint{

	private first_name: string;
	private last_name: string;
	private email: string;
	private phone: Object[];
	private date_created: string; //yyyy-mm-dd
	private website: string;
	private title: string;
	private employer: Reference;
	private is_ap_contact: boolean;

	get IsAPContact() : boolean { return this.is_ap_contact; }
	get Name() : string { return this.first_name + " " + this.last_name; }

	get FirstName() : string { return this.first_name; }
	set FirstName(name: string) { this.first_name = name; }

	get LastName() : string { return this.last_name; }
	set LastName(name: string) { this.last_name = name; }

	get Employer() : Reference { return this.employer; }

	get Email() : string { return this.email; }
	set Email(email: string) { this.email = email; }

	get Phone() : Object[] { return this.phone; }
	set Phone(phones: Object[]) { this.phone = phones; }

	get Title() : string { return this.title; }
	set Title(title: string) { this.title = title; }

	get DateCreated() : Date { return new Date(this.date_created); }
	set DateCreated(date: Date) { this.date_created = date.toISOString().slice(0, 10); }

	public toReference() : Reference{
		return {
			"id": this.id(),
			"name": this.first_name + " " + this.last_name
		};
	}


	constructor(_id?: string){
		super(_id);
	}


	static dbImport(contactSource: Object) : Contact{
		let contact = new Contact(contactSource['_id']);
		for (let property of Object.keys(contactSource)){
			if(property !== '_id'){
				contact[property] = contactSource[property];
			}
		}
		return contact;
	}

	setDefaultMembers(){
		this.members = [];
		this.groups = [{
			"name": "sales_admins",
			"scopes": ["read", "write"]
		}]
		if (this.is_ap_contact){
			this.groups.push({
				"name": "finance_admins",
				"scopes": ["read", "write"]
			});
		}
	}

}
