import { Injectable } from '@angular/core';
import { SessionDataService } from 'app/shared/services/session-data.service';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Test } from 'app/prescreener/classes';


@Injectable()
export class GlobalService implements Resolve<boolean>{

	public static OUR_PANEL: string = "quest";
	private static GLOBALS: Object = {};

	/* Application Variables */
	private static PHONE_TYPES: string[] = ["work", "home", "mobile", "other"];
	private static ACTIVITY_TYPES: string[] = ['call', 'todo', 'email', 'meeting'];

	private static DEFUALT_PARTNER_GROUPS: {}[] = [
		{
			name: "project_managers_north_america",
			scopes: [
				'read'
			]
		}
	]

	private modules_loaded: string[] = [];

	constructor(private session_data: SessionDataService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		let modules = route.data["modules"];
		if (!Array.isArray(modules) || modules.length == 0) return false;
		modules = modules.filter(module => this.modules_loaded.indexOf(module) == -1);
		//  all required modules loaded.
		if (modules.length == 0) return true;
		return this.session_data.apiGet("/settings?modules=" + modules.join(",")).pipe(
			map(data => {
				this.initializeGlobals(data.result);
				Array.prototype.push.apply(this.modules_loaded, modules);
				return true;
			}),
			catchError(e=>throwError(e))
		);
	}

	public initializeGlobals(globals: Object) {
		Object.assign(GlobalService.GLOBALS, globals);
	}

	get SendgridDefaultTemplateId(): string { return GlobalService.GLOBALS["SENDGRID_DEFAULT_TEMPLATE_ID"]; }
	get InvoicingStatuses(): string[] { return GlobalService.GLOBALS["INVOICING_STATUSES"]; }
	get ProjectStatuses() : string[] { return GlobalService.GLOBALS["FIELDING_STATUSES"]; }
	get ProgrammingTypes(): string[] { return GlobalService.GLOBALS["PROGRAMMING_TYPES"]; }
	get CryptoTypes(): string[] { return GlobalService.GLOBALS["CRYPTO_TYPES"]; }
	get SamplingMethods(): string[] { return GlobalService.GLOBALS["SAMPLING_METHODS"]; }
	get Methodologies() : string [] { return GlobalService.GLOBALS["METHODOLOGIES"]; }
	get BidStatuses(): string[] { return GlobalService.GLOBALS["BID_STATUSES"]; }
	get StudyTypes(): string[] { return GlobalService.GLOBALS["STUDY_TYPES"]; }
	get DefaultProgrammingTypes(): string[] { return GlobalService.GLOBALS["DEFAULT_PROGRAMMING_TYPES"]; }
	get Languages() { return GlobalService.GLOBALS["LANGUAGES"]; }
	get CostCategories(): string[] { return GlobalService.GLOBALS["COST_CATEGORIES"]; }
	get ProjectRoles(): Object[] { return GlobalService.GLOBALS["PROJECT_ROLES"]; }
	get ClientRoles(): Object[] { return GlobalService.GLOBALS["CLIENT_ROLES"]; }
	get Currencies(): string[] { return GlobalService.GLOBALS["CURRENCIES"]; }
	get LeadStatuses(): string[] { return GlobalService.GLOBALS["LEAD_STATUSES"]; }
	get DetectTests(): Test[] { return GlobalService.GLOBALS["DETECT_TESTS"]; }
	get DetectDefaultTests(): Test[] { return GlobalService.GLOBALS["DEFAULT_DETECT_TESTS"]; }
	get ScreenerUrl(): string {
		if (this.session_data.PRODUCTION)
			return GlobalService.GLOBALS["SCREENER_URL"];
		else
			return GlobalService.GLOBALS["SCREENER_URL_DEV"];
	}
	get PhoneTypes(): string[] { return GlobalService.PHONE_TYPES; }
	get ActivityTypes(): string[] { return GlobalService.ACTIVITY_TYPES; }
	get DefualtPartnerGroups(): {}[] { return GlobalService.DEFUALT_PARTNER_GROUPS; }

	public getOther(key: string) {
		return GlobalService.GLOBALS[key.toUpperCase()];
	}

}
