import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SessionDataService } from 'app/shared/services';

@Component({
	selector: 'qm-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnDestroy {

	pageName: string = "Home";
	private route_sub;

	constructor(private session_data: SessionDataService, private router: Router) {
		this.route_sub = router.events.subscribe((routeEvent:any) => {
			if(routeEvent.url == '/'){
				this.pageName = 'Home';
			}else{
				// Strip leading / and Capitalize following word
		if (routeEvent.url !== undefined)
				this.pageName = routeEvent.url[1].toUpperCase() + routeEvent.url.slice(2);
			}
		});
	}

	ngOnDestroy(){
		if(this.route_sub){
			this.route_sub.unsubscribe();
		}
	}

}
