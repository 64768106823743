import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { SessionDataService } from 'app/shared/services/session-data.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

	constructor(private injector: Injector) {
	}

	handleError(error: Error){
		const session_data: SessionDataService = this.injector.get(SessionDataService);
		session_data.triggerWebhook('errors',{
			path: window.location.pathname,
			message: error.message,
			name: error.name,
			stack: error.stack
		});
		console.error(error);
	}

}
