import { Component, Input } from '@angular/core';

@Component({
	selector: 'qm-payout-status-icon',
	template: `<span [ngSwitch]="status" class="status-icon">
		<i *ngSwitchCase="'done'" class="far fa-check-circle fg-positive"></i>
		<i *ngSwitchCase="'success'" class="far fa-check-circle fg-positive"></i>
		<i *ngSwitchCase="'running'" class="fas fa-spinner-third common-spinner"></i>
		<i *ngSwitchCase="'pending'" class="far fa-pause"></i>
		<i *ngSwitchCase="'approval_required'" class="fas fa-file-invoice-dollar fg-neutral"></i>
		<i *ngSwitchCase="'failed'" class="far fa-times-circle fg-negative" title="Errors: {{errors | json}}"></i>
		<i *ngSwitchCase="'rejected'" class="far fa-times-circle fg-negative"></i>
		<i *ngSwitchDefault class="fal fa-question"></i>
	</span>
	{{status | titleize}}`,
	styles: []
})
export class PayoutStatusIconComponent {
	@Input('status') status: string;
	@Input('errors') errors: string[] = [];
	constructor() {}
}
