const MONTHS: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
import { AuthConstraint } from 'app/shared/classes/auth-constraint';
import { Reference, Accessor } from 'app/shared/interfaces';


export class Client extends AuthConstraint {

	private name: string;
	private industry: string;
	private website: string;
	private logo_url: string;
	private num_employees: number;
	private date_created: string; // yyyy-mm-dd
	private company_address: Object;
	private billing_address: Object = {};
	private source: string;
	private status: string;
	private created_by: Reference;
	private gdpr_compliant: string;
	public pre_approved_tier: number;

	get Name() : string { return this.name; }
	set Name(name: string) { this.name = name; }

	get CompanyAddress() :Object {
		if (this.company_address == undefined) return undefined;
		if (this.company_address['same_as_billing'] == true){
			return this.billing_address;
		}else return this.company_address;
	}
	set CompanyAddress(CompanyAddress: Object){ this.company_address = CompanyAddress; }
	get BillingAddress() : Object{ return this.billing_address; }
	set BillingAddress(billing_address: Object){ this.billing_address = billing_address }
	get AddressesMatch() : boolean {
		if (this.company_address == undefined) return true;
		return this.company_address['same_as_billing'] == true;
	}

	get GdprCompliant():string{ return this.gdpr_compliant ;}
	set GdprCompliant(gdpr_compliant: string) { this.gdpr_compliant = gdpr_compliant; }

	get Industry() :string { return this.industry; }
	set Industry(industry:string) { this.industry = industry; }
	get Website() : string { return this.website; }
	set Website(website: string) { this.website = website; }

	get DateCreated(): string { return this.date_created;}
	set DateCreated(created : string ) { this.date_created = created; }

	get NumEmployees() : number { return this.num_employees; }
	get LogoUrl() : string { return this.logo_url; }
	get Source(): string { return this.source; }
	set Source(source: string) { this.source = source; }

	get Status() : string { return this.status; }
	set Status(status: string) { this.status = status; }

	get AccountManager() : Accessor {
		let i = this.findMemberByRole("account_manager");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set AccountManager(member: Accessor) {
		if(member !== null && member !== undefined){
			member = {
				"id": member.id,
				"name": member.name,
				"label": "Account Manager",
				"role": "account_manager",
				"scopes": ["read", "write"]
			}
		}
		let i = this.findMemberByRole("account_manager");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}


	get CreatedBy() : Reference { return this.created_by; }
	set CreatedBy(author: Reference ) { this.created_by = author; }

	get PreApprovedTier() : number { return this.pre_approved_tier; }
	set PreApprovedTier(pre_approved_tier: number) { this.pre_approved_tier = pre_approved_tier; }

	public toReference(){
		let id = this.id();
		return {"id": id, "name": this.name };
	}

	constructor(_id?: string){
		super(_id);
	}

	public setDefaultMembers(){
		let add_created_by: boolean = true;
		for (let member of this.members)
			if (member.id == this.created_by.id){
				add_created_by = false;
				break;
			}
		if (add_created_by && this.created_by){
			this.members.push({
				"name": this.created_by.name,
				"id": this.created_by.id,
				"label": "Created By",
				"scopes": ["read", "write"],
				"role": "other"
			});
		}
		this.groups = [{
			"name": "sales_admins",
			"scopes": ["read", "write"]
		},{
			"name": "finance_admins",
			"scopes": ["read", "write"]
		}];
		let country = this.billing_address['country'].toLowerCase();
		if (country == "canada"){
			this.groups.push({
				"name": "sales_admins_canada",
				"scopes": ["read", "write"]
			});
		}
	}


	static dbImport(source: Object) : Client{
		let client = new Client(source['_id']);
		for (let property of Object.keys(source)){
			if(property == '_id') continue;
			client[property] = source[property];
		}
		if (client.company_address == undefined){
			client.company_address = {"same_as_billing": true};
		}
		return client;
	}

}
