import { Component, Input} from '@angular/core';

@Component({
	selector: 'qm-copy-to-clipboard',
	templateUrl: './copy-to-clipboard.component.html',
	styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboard {

	@Input() clipboard_text: string;

	constructor() {}

	copy() {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.clipboard_text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

}
