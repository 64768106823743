import { Injectable, EventEmitter} from '@angular/core';

import { SessionDataService } from 'app/shared/services/session-data.service';
import { AuthConstraint } from 'app/shared/classes';
import { Tab } from 'app/shared/components/tab.component';

/*

DEAR FUTURE SELF:

HOW THIS STUFF WORKS:
 - Each tab extends the ProjecTab class.
 	- the project_tab class has it subscribe to the update_project and
	 revert_changes emitters within project.service
	- when a tab is left/deactivated (ngOnDestroy), the subscription is terminated
	   UNLESSS changes to that tab were made.
	- If changes to the tab were made, it will continue to respond to update and revert events.
	- When it receives these changes, each listening (changed) tab will make an api_request to update
	the project accordingly.
	- Once each tab is Updated or Reverted, we must mark that tab as unchanged.
		This will decrement the number of changed tabs as the revisions or updates are reflected thougout
		all updated tabs.  If num_tabs_changing == 0, we can set project_service.resource_saved to true, hiding the buttons.
		At this point, a notification will be shown.
	- Since all tabs are up-to-date, they can all unsubscribe from changes, except for the current tab since
	  the user may continue to make changes on that tab.


	 IMPORTANT NOTE:
	 given project_tab implements ngOnDestroy, any tab which also implements that,
	 MUST call super.ngOnDestroy
*/


@Injectable()
export abstract class TabParentService {

	public IS_UPDATING : number = 1;
	public IS_REVERTING : number = 2;
	private action: number = -1;
	private resource_saved: boolean = true;
	private resource_invalid: boolean = false;


	public revert_changes : EventEmitter<boolean> = new EventEmitter<boolean>();
	public update_changes: EventEmitter<boolean> = new  EventEmitter<boolean>();


	get Action():number {return this.action;}
	get ResourceSaved() { return this.resource_saved; }
	set ResourceSaved(saved: boolean) {
		this.resource_saved = saved;
		if (saved) {
			if (this.action == this.IS_UPDATING) this.original_resource = this.clone(this.working_copy);
			this.action = -1;
		}
	}

	set ResourceInvalid(invalid: boolean){ this.resource_invalid = invalid; }
	get ResourceInvalid(): boolean{ return this.resource_invalid; }

	private original_resource: AuthConstraint;
	protected working_copy: AuthConstraint;
	protected abstract dbImport;


	abstract get Original();
	abstract set Original(original: AuthConstraint);
	get OriginalResource(): AuthConstraint { return this.original_resource; }
	// create working copy once original is set.
	set OriginalResource(resource: AuthConstraint) {
		if (this.original_resource == undefined && resource !== undefined){
			this.working_copy = this.clone(resource);
		}
		this.original_resource = resource;
	}

	public replaceResource(resource: AuthConstraint){
		this.working_copy = this.clone(resource);
		this.original_resource = resource;
	}

	// destination = resource
	protected clone(resource: AuthConstraint) : AuthConstraint{
		let clone = this.dbImport(JSON.parse(JSON.stringify(resource)));
		clone.id = () => resource.id();
		return clone;
	}


	constructor(protected session_data: SessionDataService ) {}

	public getComponentPath() : string {
		return this.session_data.Router.url.slice(this.session_data.Router.url.lastIndexOf("/") +1);
	}


	public updateResource() : void {
		if (this.original_resource.hasWriteAccess(this.session_data.CurrentUser)) {
			if (this.action == this.IS_UPDATING) return; // update already in progress.  prevent super-fast double click
			this.action = this.IS_UPDATING;
			this.update_changes.emit(true);
		}else{
			this.session_data.pushNotificationTimeout("No Write Permission. Reverting...", "Revert","fa-check-circle","error", null, 3000);
			this.revertChanges();
		}
	}

	public revertChanges() : void {
		this.action = this.IS_REVERTING;
		this.working_copy = this.clone(this.original_resource);
		this.revert_changes.emit(true);
	}

}
