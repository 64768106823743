import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel } from '@angular/router';
import { SessionDataService,VersionCheckService } from 'app/shared/services';
import { environment } from 'app/../environments/environment';

@Component({
	selector: 'qm-root',
	template: `
		<qm-header [class.QA]="is_qa"></qm-header>
			<main>
				<ng-template [ngIf]="!session_data.hideNav">
					<div class="nav-spacer {{session_data.navState}}"></div>
					<qm-navbar [class]="session_data.navState"></qm-navbar>
				</ng-template>

				<qm-notifications (clear_notification)="session_data.clearNotification($event)"></qm-notifications>

				<div id="overlay" [class]="session_data.navState" (click)="session_data.closeNav()" [class.loading]="loading_module" [class.confirming]="session_data.is_confirming">

					<div *ngIf="loading_module" id="loading-exterior">
						<qm-loading-icon message="Loading..." [style]="'spinner'" scale="4"></qm-loading-icon>
					</div>

					<div id="confirmation-exterior" *ngIf="session_data.IsConfirming">
						<qm-confirmation
						[message]="session_data.confirmation_message"
						[confirm_message]="session_data.confirm_message"
						[deny_message]="session_data.deny_message"
						(reponse)="session_data.confirmResponse($event)"
						></qm-confirmation>
					</div>

				</div>

				<router-outlet></router-outlet>
				<router-outlet name="popup"></router-outlet>

			</main>


	`,
	styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit{
	private is_qa;
	private loading_module: boolean = false;
	constructor(private router: Router, private session_data: SessionDataService, private version_check: VersionCheckService){}
	ngOnInit(){
		this.version_check.initVersionCheck(environment['vesion_check_url']);

		this.router.events.subscribe(event => {
			if (event instanceof RouteConfigLoadStart) {
				this.loading_module = true;
			} else if (event instanceof RouteConfigLoadEnd || event instanceof NavigationCancel) {
				this.loading_module = false;
			}
		});

	}
}
