import { Injectable } from '@angular/core';
import { Route, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SessionDataService } from 'app/shared/services';



@Injectable()
export class AuthGaurd implements CanActivate, CanActivateChild, CanLoad {

	constructor(private session_data : SessionDataService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) : Observable<boolean> | boolean {
		if (this.session_data.isAuthenticated){
			if (this.session_data.AppInitialized) return true;
			else return this.session_data.reinitializeApp();
		}else{
			this.sendToLogin(state.url);
			return false;
		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) : Observable<boolean> | boolean {
		if (this.session_data.isAuthenticated){
			return true;
		}else{
			this.sendToLogin(state.url);
			return false;
		}
	}

	canLoad(route: Route): boolean {
		if (this.session_data.isAuthenticated){
			return true;
		}else{
			this.sendToLogin();
			return false;
		}
	}

	sendToLogin(login_url?:string){
		localStorage.setItem("login_url",login_url);
		this.session_data.Router.navigate(['/login']);
	}


}
