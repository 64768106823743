import * as ReportsActions from './reports.actions';

import { Dashboard, ApiError } from 'app/shared/interfaces';

export interface State{
	loading: boolean,
	initialized: boolean,
	dashboards: Dashboard[],
	dashboards_error: ApiError,
}

export const INITIAL_STATE: State = {
	loading: false,
	initialized: false,
	dashboards: [],
	dashboards_error: null,
};

export function reducer(state: State = INITIAL_STATE, action: ReportsActions.PanelsActions){
	switch(action.type){
		case ReportsActions.LOAD_DASHBOARDS:
			return {
				...state,
				loading: true
			};
		case ReportsActions.LOAD_DASHBOARDS_SUCCESS:
			return {
				...state,
				loading: false,
				initialized: true,
				dashboards: action.payload
			};
		case ReportsActions.LOAD_DASHBOARDS_FAILURE:
			return {
				...state,
				loading: false,
				initialized: true,
				dashboards_error: action.payload
			};
		default:
			return state;
	}
}
