import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionDataService } from 'app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
	templateUrl: './report-bug.component.html',
	styleUrls: ['./report-bug.component.scss']
})
export class ReportBugComponent implements OnInit {

	private gitlab_url = 'https://gitlab.v-eden.com/api/v4';
	private gitlab_project_id=35; // Titan
	private labels: any = null;
	private sub:any;
	private issue_id: number = null;
	private token_ready: boolean = false;

	private account_sub;
	private route_sub;
	private label_sub;
	private issue_sub : Subscription;
	private issues: any = null;
	private form_group: FormGroup;
	private selected_labels: string[] = [];
	private uploaded_files_markdown: string[] = [];



	private assignees = [
		{
			"name":"Ryan",
			"id":2
		},
		{
			"name":"Krista",
			"id":3
		},
		{
			"name":"Austin",
			"id":70
		}
	];




	constructor(private session_data:SessionDataService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.issue_id = null;
		this.form_group = new FormGroup({
			"title": new FormControl("", Validators.required),
			"description": new FormControl("", Validators.required),
			"assignee": new FormControl("", Validators.required)
		});

		// Check if gitlab account exists
		if(!this.session_data.CurrentUser.GitlabToken || this.session_data.CurrentUser.GitlabToken == "" ){
			// console.log("Initializing Gitlab account...");
			this.account_sub = this.session_data.createGitlabAccount().subscribe(
				result => {
					this.token_ready = result
					this.loadLabelsFromGitlab();
				}
			);
		}else{
			this.token_ready = true;
			this.loadLabelsFromGitlab();
		}

		this.route_sub = this.route.params.subscribe(
			params => {
				// console.log("Params");
				// console.log(params);
				if(params['issue_id'] != undefined){
					this.issue_id = params['issue_id'];
				}
			}
		);
	}

	loadLabelsFromGitlab(){
		this.label_sub = this.session_data.get(`${this.gitlab_url}/projects/${this.gitlab_project_id}/labels`, [this.session_data.getGitlabTokenHeader()]).subscribe(
			data => {
				this.labels = data
				this.issue_sub = this.session_data.get(`${this.gitlab_url}/projects/${this.gitlab_project_id}/issues?state=opened`, [this.session_data.getGitlabTokenHeader()]).subscribe(
					data => {
						this.issues = data;
					},
					this.session_data.apiErrorHandler
				);
			},
			this.session_data.apiErrorHandler
		);
	}

	hasLabel(l: string){
		return this.selected_labels.indexOf(l) !== -1;
	}
	toggleLabel(l: string){
		if(this.hasLabel(l)){
			this.selected_labels.splice(this.selected_labels.indexOf(l),1);
		}else{
			this.selected_labels.push(l);
		}
	}

	submitForm(){
		let description = this.form_group.value.description;

		// Add uploaded images to the description's markdown
		if(this.uploaded_files_markdown.length > 0){
			description += "\n#### Attachments\n";
		}
		for(let markdown of this.uploaded_files_markdown){
			description += "\n!"+ markdown+"\n";
		}

		let body = {
			"title":this.form_group.value.title,
			"description":description,
			"assignee_ids":[this.form_group.value.assignee],
			"labels":this.selected_labels.join(',')
		};
		this.issue_sub = this.session_data.post(`${this.gitlab_url}/projects/${this.gitlab_project_id}/issues`,body, [this.session_data.getGitlabTokenHeader()]).subscribe(
			data => console.log(data),
			this.session_data.apiErrorHandler,
			() => this.session_data.Router.navigate([`/`])
		);
	}

	getColor(label:string){
		if(this.labels){
			for(let i of this.labels){
				if(i.name == label)
					return i.color;
			}
		}
		return null;
	}

	openIssue(id: number){
		window.open(`https://gitlab.v-eden.com/quest/titan/issues/${id}`);
	}

	ngOnDestroy(){
		if(this.issue_sub) this.issue_sub.unsubscribe();
		if(this.account_sub) this.account_sub.unsubscribe();
		if(this.route_sub) this.route_sub.unsubscribe();
		if(this.label_sub) this.label_sub.unsubscribe();
	}

}
