import { Component, Output, EventEmitter } from '@angular/core';
import { animate, transition, trigger, style } from '@angular/animations';
import { SessionDataService } from 'app/shared/services';

@Component({
	selector: 'qm-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	animations:[
		trigger('fadeInOut', [
			transition(':enter', [   // :enter is alias to 'void => *'
				animate(200, style({transform: 'translateX(0)'}))
			]),
			transition(':leave', [   // :leave is alias to '* => void'
				animate(200, style({transform: 'translateX(100%)'}))
			])
		])
	]
})
export class NotificationsComponent {

	@Output() clear_notification = new EventEmitter();

	constructor(private session_data: SessionDataService) { }

	emitClearNotification(index: number){
		console.log("Clearing " + index);
		this.clear_notification.emit(index);
	}

	runAction(action: Function,index: number){
		action();
		this.emitClearNotification(index);
	}

}
