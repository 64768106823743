import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'regexpipe'
})
export class RegexPipe implements PipeTransform {

	transform(list: any, search_text: any, parameters: string[]): any {
		if (search_text == undefined) return list;
		else {
			if(!parameters) parameters = Object.keys(list[1]);
			let pattern = new RegExp(this.RegFilter(search_text), "gi");
			let partnerList = [];
			for (let name of list) {
				for (let parameter of parameters) {
					if (pattern.test(name[parameter])) {
						partnerList.push(name);
						break;
					}
				}
			}
			return partnerList;
		}
	}

	RegFilter(search_text) {
		for (let index = 0; index < search_text.length; index++) {
			if (!/[a-zA-Z0-9]/.test(search_text[index])) {
				search_text = search_text.substr(0, index) + '\\' + search_text.substr(index, search_text.length);
				index++;
			}
		}
		return search_text;
	};

}
