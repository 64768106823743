import { Action } from '@ngrx/store';

import { Dashboard, ApiError, ApiResponse } from 'app/shared/interfaces';

export const LOAD_DASHBOARDS = 'LOAD_DASHBOARDS';
export const LOAD_DASHBOARDS_SUCCESS = 'LOAD_DASHBOARDS_SUCCESS';
export const LOAD_DASHBOARDS_FAILURE = 'LOAD_DASHBOARDS_FAILURE';

export class LoadDashboards implements Action{
	readonly type = LOAD_DASHBOARDS;
	constructor(){}
}
export class LoadDashboardsSuccess implements Action{
	readonly type = LOAD_DASHBOARDS_SUCCESS;
	constructor(public payload: Dashboard[]){}
}
export class LoadDashboardsFailure implements Action{
	readonly type = LOAD_DASHBOARDS_FAILURE;
	constructor(public payload: ApiError){}
}

export type PanelsActions =
	LoadDashboards |
	LoadDashboardsSuccess |
	LoadDashboardsFailure;
