import { Subscription } from 'rxjs';
import { SessionDataService } from 'app/shared/services';
import { allSort } from "./sort-list-interfaces"
import * as moment from 'moment';

interface IDateRange {
	startDate: moment.Moment,
	endDate: moment.Moment
}

export class VirtualScrollManager {

	public loading: boolean = true;
	public search_text: string;
	public last_item: boolean = false;
	public is_mine: boolean = false;
	public show_closed: boolean = true;
	public items: any[] = [];
	public date_created: IDateRange;
	public total_item_count: number = 0;

	public filter_box_all_selected: boolean = true;
	public filter_options: Object[] = [];
	public current_filter: string;
	private first_loop: boolean = true;
	

	private _sort: allSort | null;

	public get sort(): allSort | null {
		return this._sort;
	}
	public set sort(value: allSort | null) {
		this._sort = value;
		this.reset()
	}



	private filters: Object = {};

	private get_items_sub: Subscription;

	constructor(
		private session_data: SessionDataService,
		private query_url: string,
		private db_import,
		private local_storage_filter_key: string,
		private default_sort: allSort | null,
		private base_filters: Object = {},
	) {
		this.filters = JSON.parse(localStorage.getItem(local_storage_filter_key)) || {};
		this.sort = default_sort

		// reset is_closed option
		if (/project_filters/.test(this.local_storage_filter_key)) {
			this.filters = Object.assign({}, this.filters, { is_closed: [false] });
		} else {
			delete this.filters['is_closed'];
		}

		this.is_mine = (this.filters['is_mine'] !== undefined);
		this.show_closed = (this.filters["is_closed"] == undefined);
		let search = localStorage.getItem(this.local_storage_filter_key + "_search");
		// null returned from localStorage if not found.
		search = (search == null) ? undefined : search;
		this.search(search);

		let date_filter: string = localStorage.getItem(this.local_storage_filter_key + "_date_created");

		if (date_filter && date_filter.length > 0) {
			let date_filter_obj: IDateRange = JSON.parse(date_filter, (key, value) => {
				switch (key) {
					case 'startDate':
					case 'endDate':
						return moment(value);
					default:
						return value;
				}
			});
			this.filterByDate(date_filter_obj);
		}
		else {
			this.filterByDate(null);
		}
	}

	public reset() {
		this.items = [];
		this.total_item_count = 0;
	}

	get filterState(): object { return Object.assign({}, this.filters, this.base_filters); }

	public search(term: string) {
		if (term == undefined || term.length == 0) {
			localStorage.removeItem(this.local_storage_filter_key + "_search");
			this.search_text = undefined;
		} else this.search_text = term;
		this.reset();
	}

	public filterByDate(range: IDateRange) {
		if (range == undefined || range.startDate == undefined) {
			localStorage.removeItem(this.local_storage_filter_key + "_date_created");
			this.date_created = undefined;
			delete this.filters['date_created'];
		} else {
			this.date_created = range;
			this.filters['date_created'] = range;
		}
		this.reset();
	}


	public listChange(event: Object) {
		let from = event["from"];
		let limit = event["limit"];
		let overwrite = event["overwrite"];
		this.loading = true;
		this.last_item = false;

		let url: string;
		let questionMark = new RegExp("[?]");
		if (questionMark.test(this.query_url)) {
			url = `${this.query_url}&from=${from}&limit=${limit}`;
		} else {
			url = `${this.query_url}?from=${from}&limit=${limit}`;
		}

		if (this.sort) {
			url = `${url}&sort=${this.sort}`
		}

		if (this.search_text !== undefined) url += "&search=" + encodeURIComponent(this.search_text);
		const all_filters = Object.assign({}, this.base_filters, this.filters);
		this.get_items_sub = this.session_data.apiPost(url, all_filters).subscribe(
			data => {
				if (overwrite) {
					this.items = data.result.map(item => this.db_import(item));
					this.total_item_count = data.hits || 0;
				} else {
					for (let item of data.result) this.items.push(this.db_import(item));
				}
				this.last_item = (this.items.length == data.hits || data.hits == 0);
			},
			this.session_data.apiErrorHandler,
			() => this.loading = false
		);

		localStorage.setItem(this.local_storage_filter_key, JSON.stringify(this.filters));
	}


	public setFilterOptions(options: Object[], current_filter: string, no_value_filter = undefined) {
		if (no_value_filter !== undefined) {
			this.filter_options = [no_value_filter, ...options];
		} else {
			this.filter_options = options;
		}
		this.current_filter = current_filter;

		let num_selected: number = 0;
		if (this.filters[current_filter] !== undefined) {
			for (let option of this.filter_options) {
				if (this.filters[current_filter].includes(option['value'])) {
					num_selected++;
					option['selected'] = true;
				}
			}
		}
		this.filter_box_all_selected = (num_selected == options.length);
	}

	public onToggleMineOnly() {
		this.is_mine = !this.is_mine;
		if (this.is_mine) {
			this.filters['is_mine'] = this.session_data.CurrentUser.id();
		} else {
			delete this.filters['is_mine'];
		}
		this.reset();
	}

	public onToggleClosed() {
		this.show_closed = !this.show_closed;
		if (this.show_closed) {
			delete this.filters["is_closed"];
		} else {
			this.filters["is_closed"] = [false];
		}
		this.reset();
	}

	public hideClosed() {
		this.show_closed = false;
		this.filters["is_closed"] = [false];
		this.reset();
	}

	public isFilteringBy(field: string) { return field in this.filters; }


	// if none of the options are selected, remove that field as a filter.
	private validateFilters() {
		let num_terms_selected = 0;
		this.filters[this.current_filter] = [];
		for (let filter of this.filter_options) {
			if (filter['selected']) {
				this.filters[this.current_filter].push(filter['value']);
				num_terms_selected++;
			}
		}
		if (num_terms_selected == 0) delete this.filters[this.current_filter];
		this.reset();
	}

	public applyFilter(filter_index: number) {
		// unselecting the filter
		if (this.filter_options[filter_index]['selected']) {
			this.filter_options[filter_index]['selected'] = false;
			this.filter_box_all_selected = false;
			this.validateFilters();
		} else {
			// selecting the filter
			this.filter_options[filter_index]['selected'] = true;
			if (this.filter_options.every(option => { return option['selected']; })) {
				delete this.filters[this.current_filter];
				this.filter_box_all_selected = true;
				this.reset();
			} else {
				this.validateFilters();
			}
		}
	}

	public onSelectAllTerms(select_em: boolean) {
		if (select_em) {
			this.filter_options.map(option => {
				option['selected'] = true;
				return option;
			});
			this.filter_box_all_selected = true;
		} else {
			this.filter_options.map(option => {
				option['selected'] = false;
				return option;
			});
			this.filter_box_all_selected = false;
		}
		delete this.filters[this.current_filter];
		this.reset();
	}

	destroy() {
		if (this.search_text == undefined) {
			localStorage.removeItem(this.local_storage_filter_key + "_search");
		} else {
			localStorage.setItem(this.local_storage_filter_key + "_search", this.search_text);
		}

		if (this.date_created == undefined || this.date_created.startDate == undefined) {
			localStorage.removeItem(this.local_storage_filter_key + "_date_created");
		} else {
			localStorage.setItem(this.local_storage_filter_key + "_date_created", JSON.stringify(this.date_created));
		}

		if (this.get_items_sub) this.get_items_sub.unsubscribe();
	}

}
