import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';
import { SessionDataService } from 'app/shared/services/session-data.service';

@Injectable()
export class VersionCheckService {

	private interval: any;

	private new_version: string;
	private current_version = environment.version;
	private current_bundle_hash = '{{BUILD_ENTERS_HASH_HERE}}';

	private version_out_of_date: boolean = false;

	constructor(private session_data: SessionDataService) { }

	public initVersionCheck(url,frequency = 1000 * 60 * 15){
		if(environment.production === false){
			console.log("Development. Version Check disabled");
			return;
		}
		console.log("Initializing Version Check. App Version:",this.current_bundle_hash);
		this.interval = setInterval(() => {
			this.checkVersion(url);
		},frequency);
	}

	private checkVersion(url){
		this.session_data.get('/static/version.json?timestamp='+new Date().getTime()).subscribe(data => {
			if(data.bundle_hash !== this.current_bundle_hash){
				this.new_version = data.version;
				if(this.version_out_of_date !== true){
					this.version_out_of_date = true;
					this.session_data.pushNotification(
						`There is a newer version of Titan available.`,
						"message",
						"far fa-code-branch",
						"ok",
						{
							text: "Refresh",
							callback: () => { location.reload(true); }
						}
					);
				}
			}
		});
	}

}
