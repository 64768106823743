import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	NgZone,
	Input, Output,
	OnChanges, AfterViewInit, OnDestroy,
	ViewContainerRef, TemplateRef,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';

export interface ChangeEvent {
	from: number;
	limit: number;
	overwrite?: boolean;
}


// display: block;
@Component({
	selector: 'veden-virtual-scroll',
	exportAs: 'virtualScroll',
	template: `
		<div class="total-padding" #padding></div>
		<div class="scrollable-content" #content>
			<ng-content></ng-content>
		</div>
	`,
	host: {
	},
	styles: [`
		:host {
			overflow: hidden;
			overflow-y: auto;
			position: relative;
			width: 100%;
			-webkit-overflow-scrolling: touch;
			display: flex;
		}
		.scrollable-content {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
		}
		.total-padding {
			width: 1px;
			opacity: 0;
			flex: 1 0;
		}
	`]
})
export class vEdenVirtualScrollComponent implements OnChanges, AfterViewInit, OnDestroy {

	@Input() items: any[] = [];
	@Input('lastItem') last_item: boolean = false;
	@Input('itemHeight') child_height: number = 30;

	@Output() update: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() change: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();

	@ViewChild('content', { read: ElementRef }) viewport_ref: ElementRef;
	@ViewChild('padding', { read: ElementRef }) padding_ref: ElementRef;


	private recovery_loop: boolean = false;
	private previous_start: number = 0;
	private previous_end: number = 0;
	private startup_loop: boolean = true;


	private items_per_column: number;
	private num_items_requested: number = 0;
	private BUFF_SIZE: number = 0;

	@HostListener('scroll') onScroll() { this.refresh(); }
	@HostListener('window:resize') onResize() {
		this.renderer.setStyle(this.element.nativeElement, 'max-height', "none");
		this.renderer.setStyle(this.element.nativeElement, 'height', "auto");
		this.renderer.setStyle(this.padding_ref.nativeElement, 'max-height', "0px");
		this.setViewPortDimensions();
		this.refresh();
	}


	/** Cache of the last scroll height and top_padding to prevent setting CSS when not needed. */
	private lastScrollHeight = -1;
	private last_top_padding = -1;

	constructor(
		private readonly element: ElementRef,
		private readonly renderer: Renderer2,
		private readonly zone: NgZone) { }

	ngAfterViewInit() {
		setTimeout(() => {
			this.setViewPortDimensions();
			this.BUFF_SIZE = Math.ceil(this.items_per_column * 0.25);
			this.num_items_requested = this.items_per_column + this.BUFF_SIZE;
			this.change.emit({ 'from': 0, 'limit': this.num_items_requested, "overwrite": true });
		}, 0)
	}


	setViewPortDimensions() {

		let viewport_height = this.element.nativeElement.clientHeight;
		this.items_per_column = Math.max(1, Math.ceil(viewport_height / this.child_height));

		this.renderer.setStyle(this.element.nativeElement, 'height', `${viewport_height}px`);
		this.renderer.setStyle(this.element.nativeElement, 'max-height', `${viewport_height}px`);
		this.renderer.setStyle(this.padding_ref.nativeElement, 'height', `${viewport_height}px`);
		this.renderer.setStyle(this.padding_ref.nativeElement, 'max-height', `${viewport_height}px`);
	}

	checkLastItem(changes: SimpleChanges): boolean {
		if (changes["last_item"] !== undefined) {
			let last_item = (changes as any).last_item.currentValue;
			if (last_item) {
				this.recovery_loop = false;
				return true;
			}
		}
		return false;
	}

	ngOnChanges(changes: SimpleChanges) {
		try {
			if (Array.isArray(changes["items"]["currentValue"])) {
				let items_current = (changes as any).items.currentValue;
				let items_previous = (changes as any).items.previousValue;
				if (items_current == undefined) return; // not useful for anything.

				if (items_current.length == 0 && items_previous !== undefined) { // reset to []
					if (this.checkLastItem(changes)) return;
					this.num_items_requested = 0;
					this.previous_start = -1;
					this.refresh();
				} else if (items_current.length > 0) {
					this.recovery_loop = false;
					this.refresh();
				}
			}
		} catch (e) { }
	}


	refresh() {
		this.zone.runOutsideAngular(() => {
			requestAnimationFrame(() => this.calculateItems());
		});
	}


	setScrollHeight(): number {
		let scroll_height = this.child_height * this.items.length;
		this.renderer.setStyle(this.padding_ref.nativeElement, 'max-height', "none");
		this.renderer.setStyle(this.padding_ref.nativeElement, 'height', `${scroll_height}px`);
		this.lastScrollHeight = scroll_height;
		return scroll_height;
	}


	private calculateItems() {

		NgZone.assertNotInAngularZone();
		let el = this.element.nativeElement;

		let scroll_height = this.setScrollHeight();
		let scrollTop = Math.max(0, el.scrollTop);
		let indexByScrollTop = (scroll_height > 0) ? scrollTop / scroll_height * this.items.length : 0;
		let end = Math.min(this.items.length, Math.ceil(indexByScrollTop) + this.items_per_column);
		let start = Math.floor(indexByScrollTop);
		let top_padding = this.child_height * start;


		if (top_padding !== this.last_top_padding) {
			this.renderer.setStyle(this.viewport_ref.nativeElement, 'transform', `translateY(${top_padding}px)`);
			this.renderer.setStyle(this.viewport_ref.nativeElement, 'webkitTransform', `translateY(${top_padding}px)`);
			this.last_top_padding = top_padding;
		}


		// scroll though loaded items -- update list with existing items
		if ((end !== this.previous_end || start !== this.previous_start)) {
			this.zone.run(() => {
				this.update.emit(this.items.slice(start, end));
				this.previous_start = start;
				this.previous_end = end;
			});
		}

		// waiting for new items...
		if (this.recovery_loop) {
			if (this.items.length >= this.num_items_requested) {
				this.recovery_loop = false;  // new items received
			} else {
				if (this.last_item) return;
				setTimeout(() => this.refresh(), 350);
				return;
			}
		}

		// scrolled into buffer, and not waiting on items.
		if (end >= (this.num_items_requested - this.BUFF_SIZE) && !this.recovery_loop) {
			// request new items
			this.zone.run(() => {
				let change_event: ChangeEvent = { "from": this.num_items_requested, "limit": this.items_per_column, "overwrite": false };
				if (this.num_items_requested == 0) {
					change_event.limit += this.BUFF_SIZE;
					change_event.overwrite = true;
				}
				this.change.emit(change_event);
				this.num_items_requested += change_event.limit;
				this.recovery_loop = true; // recovery_loop used for busy waitng...
				setTimeout(() => this.refresh(), 350);
			});
		}

	}

	ngOnDestroy() {
		this.recovery_loop = false;
	}
}
