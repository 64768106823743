import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'qm-json-display',
	templateUrl: './json-display.component.html',
	styleUrls: ['./json-display.component.scss']
})
export class JsonDisplayComponent implements OnInit {

	@Input() json: Object[];
	@Input() root: boolean = true;;

	private keys_storage: Object;
	private types_storage: Object;
	constructor() {}

	ngOnInit() {
		this.keys_storage = {}
		this.types_storage = {}
	}

	keys(id: string, obj: Object,skip: number = 0){
		if(this.keys_storage[id] === undefined) this.keys_storage[id] = Object.keys(obj);
		return this.keys_storage[id];
	}

	getType(key,value: any){
		if(this.types_storage[key] === undefined){
			this.types_storage[key] = typeof value;
			if(Array.isArray(value)) this.types_storage[key] = 'array';
		}
		return this.types_storage[key];
	}

}
