import { Component, Input, HostBinding } from '@angular/core';
import { Project } from 'app/shared/classes';

@Component({
	selector: 'qm-project-list-item',
	template: `
	<ng-template [ngIf]="project">
		<div class="project-number">{{project.Number}}
		<ng-template [ngIf]="project.Wave > 1">({{project.Wave}})</ng-template></div><!--
	--><div class="project-name">{{project.Name}}</div><!--
	--><div class="project-status"><span>{{project.status}}</span><!--
		--><ng-template [ngIf]="project.Status == 'Out of Field'">
			<i class="far fa-gavel" *ngIf="project.InvoicingStatus == 'Pending Sign Off'" title="Sign-off requested from {{project.SignOffMember?.name}}"></i><!--
			--><i class="far fa-file-invoice-dollar" *ngIf="project.InvoicingStatus == 'Ready for Invoice'" title="Invoicing Submitted"></i><!--
			--><i class="far fa-file-invoice-dollar red" *ngIf="project.InvoicingStatus == 'Invoiced'" title="Awaiting payment"></i><!--
			--><i class="far fa-file-invoice-dollar" *ngIf="project.InvoicingStatus == 'Complete'" title="Complete"></i><!--
			--><i class="far fa-lock-alt" *ngIf="project.IsClosed" title="Closed"></i>
		</ng-template>
		</div><!--
	--><div class="project-client">{{project.Client?.name}}</div><!--
	--><div class="project-pm">{{project.ProjectManager?.name}}</div><!--
	--><div class="sales-rep">{{project.SalesRep?.name}}</div><!--
	--><div class="study-type">{{project.StudyType}}</div><!--
	--><div class="project-is-wholesale"><i class="fa fa-check" *ngIf="project.IsWholesale"></i></div><!--
	--><div class="start-date">{{project.DateCreated | date:'mediumDate'}}</div><!--
	--><div class="start-date">{{formatAmount(project.quoteTotal)}}</div><!--
	--><div *ngIf="project.IsClosed" class="start-date">{{formatAmount(project.invoiceTotal)}}</div>
	</ng-template>
	`,
	styleUrls: ['./project-list-item.component.scss']
})
export class ProjectListItemComponent {
	@HostBinding('class.closed') get closed () { return this.project.IsClosed; }
	@Input('project') project: Project;
	constructor() { }
	formatter = new Intl.NumberFormat('en-US', {
		style: "currency",
		currency: "USD"
	})
	formatAmount(number){
		return this.formatter.format(number)
	}
}
