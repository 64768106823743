const COLORS: any = {
	red: { primary: '#ad2121', secondary: '#fae3e3' },
	blue: { primary: '#1e90ff', secondary: '#d1e8ff' },
	green: { primary: '#21ab21', secondary: '#7ee77e' },
	yellow: { primary: '#e3bc08', secondary: '#fdf1ba' },
};


import { Reference, Accessor } from 'app/shared/interfaces';
import { AuthConstraint } from 'app/shared/classes/auth-constraint';

export class Activity extends AuthConstraint {

	private type: string;
	private description: string;
	private stage: string;
	private title: string;
	private static sales_rep_ids: string[];
	private done: boolean;
	private made_contact: boolean;
	private start_time: Date;
	private end_time: Date;
	private date_created: Date;
	private client: Reference;
	private client_participants: Reference[];
	private quest_participants: Reference[];

	constructor(_id?: string){
		super(_id);
	}

	get ClientParticipants() : Reference[] { return this.client_participants; }
	set ClientParticipants(client_contacts: Reference[] ) { this.client_participants = client_contacts; }

	get QuestParticipants() : Reference[] { return this.quest_participants; }
	set QuestParticipants(quest_contacts: Reference[] ){
		this.quest_participants = quest_contacts;
	}

	get Client(): Reference { return this.client; }
	get Type() : string { return this.type; }
	get Stage() : string { return this.stage; }
	get Title() : string { return this.title; }
	get Description() : string { return this.description; }
	get Done() : boolean { return this.done; }
	get MadeContact() : boolean { return this.made_contact; }
	get StartTime(): Date { return this.start_time; }
	get EndTime(): Date { return this.end_time; }
	get TypePretty(){
		switch (this.type) {
			case "call": return "Phone Call";
			case "todo": return "Todo";
			case "email": return "Email";
			case "meeting": return "Meeting";
		}
	}

	public toReference() : Reference{
		return {
			"id": this.id(),
			"name": this.TypePretty + " - " + this.Client.name
		}
	}

	static dbImport(source: Object) : Activity{
		let activity = new Activity(source['_id']);
		for (let property of Object.keys(source))
			if(property !== '_id'){
				activity[property] = source[property];
			}
		return activity;
	}

	toEvent(): Object {
		return {
			end: new Date(this.EndTime),
			start: new Date(this.StartTime),
			title: this.type.charAt(0).toUpperCase() + this.type.slice(1) + " with " + this.client['name'],
			color: this.setColor(this.Type),
			activity: this
		}
	}

	setColor(type: string): Object {
		switch (type) {
			case 'call': return COLORS.red;
			case 'todo': return COLORS.yellow;
			case 'email': return COLORS.green;
			case 'meeting': return COLORS.blue;
		}
	}

	/*
		Will return an event for each quest member involved in the activity.
		ex.: if Joe and Monica are on the same call, 2 events will be created - one for Joe, and one for Monica
	*/
	toCalendarEvents(sales_people: Object[]) : Object[]{
		let calendar_events = [];

		// same for all members
		let activity_base = {
			'activity_id': this.id(),
			'title': this.type.charAt(0).toUpperCase() + this.type.slice(1) + " with " + this.client['name'],
		};
		if (this.start_time !== undefined) activity_base['start'] = this.StartTime;
		if (this.end_time !== undefined) activity_base['end'] = this.EndTime;

		if (Activity.sales_rep_ids == undefined){
			Activity.sales_rep_ids = sales_people.map(rep => rep['id']);
			console.log(Activity.sales_rep_ids);
		}

		// set the sales_rep for the calendar_event
		for (let sales_rep of this.QuestParticipants){
			// append to activity_base, the appropriate color and sales_rep
			let calendar_event = Object.assign(activity_base, {
				'sales_rep': sales_rep,
				// this rep's color (index in session_data.getUsersByRole("Sales") == index in colors)
				'color': Activity.sales_rep_ids.indexOf(sales_rep['id'])
			});
			calendar_events.push(calendar_event);
		}

		// return several calendar_events
		return calendar_events;
	}


	setDefaultMembers(){
		this.members = this.quest_participants.map(
			participant => {
				participant['label'] = "Participant";
				participant['scopes'] = ["read", "write"];
				participant["property"] = "participants";
				return <Accessor> participant;
			}
		);
		this.groups = [
			{
				"name": "sales_admins",
				"scopes": ["read"]
			}
		];
	}
}
