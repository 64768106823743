import { Reference, Accessor, Qualification, Cost, PII } from 'app/shared/interfaces';
import { AuthConstraint } from 'app/shared/classes/auth-constraint';



export class Bid extends AuthConstraint {

	private date_created: Date;
	private created_by: Reference;

	private name : string;
	private number: string;
	private study_type : string;
	private sampling_method : string;
	private is_wholesale : boolean = false; // default
	private length_of_interview : number;

	private tags: string[] = [];
	private status : string;

	private client : Reference;
	private contacts: Reference[] = [];
	private po_number: string;
	private currency: string = "USD";

	private panelist_description: string;
	private timeframe: string;
	private programming_requirements: string[] = [];
	private custom_programming: string = "";
	private host_survey: boolean = false;
	private quote: Cost[] = [];
	private overall_target: number;

	private pii: PII = {
		"quest_survey": false,
		"quest_detect": false,
		"client_survey": false
	};


	get SalesRep() : Accessor {
		let i = this.findMemberByRole("sales_rep");
		if(i !== -1) return this.members[i];
		else return undefined;
	}
	set SalesRep(member: Accessor) {
		if(member !== null && member !== undefined){
			member.label = "Sales Representative";
			member.role = "sales_rep";
			member.scopes = ["read", "write"];
		}
		let i = this.findMemberByRole("sales_rep");
		if(i !== -1){
			if(member === null || member === undefined) this.members.splice(i,1);
			else this.members[i] = member;
		}else if(member !== null && member !== undefined){
			this.members.push(member);
		}
	}


	get Status() : string { return this.status; }
	set Status(status : string) { this.status = status; }

	get PII() : PII { return this.pii; }
	set PII(pii: PII) { this.pii = pii; }

	get Tags() : string[] { return this.tags; }
	set Tags(tags: string[]) { this.tags = tags; }

	get CreatedBy() : Reference { return this.created_by; }
	set CreatedBy(creator : Reference) { this.created_by = creator; }
	
	// stored as a string to be searchable by regex in mongoDb
	get Number() : string { return this.number; }
	set Number(number: string) { this.number = number;}

	get Name() { return this.name; }
	set Name(name: string) { this.name = name; }

	get Client() { return this.client; }
	set Client(client: Reference) { this.client = client; }

	get Contacts() : Reference[] { return this.contacts; }
	set Contacts(contacts: Reference[]) { this.contacts = contacts; }

	get StudyType() { return this.study_type; }
	set StudyType(type: string) { this.study_type = type; }

	get SamplingMethod() { return this.sampling_method; }
	set SamplingMethod(method: string) { this.sampling_method = method; }

	get IsWholesale() : boolean { return this.is_wholesale || false; }
	set IsWholesale(wholesale: boolean) { this.is_wholesale = wholesale; }

	get LengthOfInterview() { return this.length_of_interview; }
	set LengthOfInterview(loi: number){ this.length_of_interview = loi; }

	get OverallTarget() : number { return this.overall_target; }
	set OverallTarget(target: number ) { this.overall_target = target; }

	get DateCreated(): Date { return this.date_created; }
	set DateCreated(date_created: Date) { this.date_created = date_created; }

	get Currency() : string { return this.currency; }
	set Currency(currency: string) { this.currency = currency; }

	get POnumber() : string { return this.po_number; }
	set POnumber(po: string) { this.po_number = po; }

	get Timeframe() : string { return this.timeframe; }
	set Timeframe(timeframe: string) { this.timeframe = timeframe; }

	get ProgrammingRequirements() : string[] { return this.programming_requirements; }
	set ProgrammingRequirements(reqts: string[]) {
		this.programming_requirements = reqts;
		this.host_survey = reqts.indexOf("Survey") !== -1;
	}

	get CustomProgramming() { return this.custom_programming; }
	set CustomProgramming(programming: string) {this.custom_programming = programming; }

	get PanelistDescription() : string {return this.panelist_description; }
	set PanelistDescription(description: string) { this.panelist_description = description; }

	get ProgrammingSource() { return (this.host_survey) ? "Quest" : "Client"; }
	get HostSurvey() { return this.host_survey; }

	get Quote() { return this.quote; }
	set Quote(quote: Cost[]) { this.quote = quote; }

	public InvoiceItemsByCategory(cat: string){
		return this.quote.filter(item => item.category == cat);
	}

	constructor(_id?: string){
		super(_id);
	}

	public toReference() {
		return {
			"id": this.id(),
			"name": this.name
		}
	}

	static dbImport(source: Object) : Bid {
		let bid = new Bid(source['_id']);
		for (let property of Object.keys(source)){
			if (property == "_id") continue;
			else bid[property] = source[property];
		}
		return bid;
	}

	public setDefaultMembers(){
		let add_created_by = true;
		for (let member of this.members)
			if (member.id == this.created_by.id){
				add_created_by = false;
				break;
			}
		if (add_created_by && this.created_by){
			this.members.push({
				"name": this.created_by.name,
				"id": this.created_by.id,
				"label": "Created By",
				"scopes": ["read", "write"],
				"role": "other"
			});
		}

		this.groups = [{
			"name": "sales_admins",
			"scopes": ["read", "write"]
		}];
	}

}
