import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionDataService } from 'app/shared/services';
import { Subscription } from 'rxjs';

@Component({
	selector: 'qm-socket-testing',
	templateUrl: './socket-testing.component.html',
	styleUrls: ['./socket-testing.component.scss']
})
export class SocketTestingComponent implements OnInit , OnDestroy {

	private response: any;
	private request_done: boolean = true;

	private get_mail_leave_sub: Subscription;
	private get_mail_wait_sub: Subscription;

	constructor(private session_data: SessionDataService) { }

	ngOnInit() {


	}
	callAndLeave(){
		this.request_done = false;
		this.get_mail_leave_sub = this.session_data.apiGet(`/mail/test?socket_id=${this.session_data.Io.id}`).subscribe(
			data => {
				this.response = data;
				this.request_done = true;
			}
		);
	}
	callAndWait(){
		this.request_done = false;
		this.get_mail_wait_sub = this.session_data.apiGet(`/mail/test`).subscribe(
			data => {
				this.response = data;
				this.request_done = true;
			}
		);
	}

	ngOnDestroy(){
		if(this.get_mail_leave_sub){
			this.get_mail_leave_sub.unsubscribe();
		}
		if(this.get_mail_wait_sub){
			this.get_mail_wait_sub.unsubscribe();
		}
	}

}
