import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from 'app/store/custom-serializer';

import * as fromReports from 'app/store/reports/reports.reducers';


export interface AppState{
	router_state: RouterReducerState<RouterStateUrl>
	reports_state: fromReports.State
};

export const reducers: ActionReducerMap<AppState> = {
	router_state: routerReducer,
	reports_state: fromReports.reducer
};
