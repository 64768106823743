import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionDataService } from 'app/shared/services';
import { Subscription } from 'rxjs/Subscription';
import { FormControl } from '@angular/forms'


@Component({
	selector: 'app-login-alternative',
	templateUrl: './login-alternative.component.html',
	styleUrls: ['./login-alternative.component.scss']
})
export class LoginAlternativeComponent implements OnInit, OnDestroy {

	public loading: boolean = false;
	private get_login_sub: Subscription;
	public accessCode = new FormControl('');
	private token: string = '';
	private email: string = '';
	public error_message = '';

	constructor(private route: ActivatedRoute, private session_data: SessionDataService) { }

	ngOnInit() {
		if (this.session_data.isAuthenticated) {
		    this.session_data.Router.navigate(['/']);
		}
		this.session_data.Title = "Quest Titan";
		this.session_data.ExpiryWarning = false;
		this.session_data.hideNav = true;
		this.token = this.route.snapshot.queryParams["token"];
	}

    onSubmit() {
        this.session_data.apiPost('/alternative-login', {access_code: this.accessCode.value, token: this.token}).subscribe(
            data => {
                this.session_data.initializeApp(data['result']);
            },
            error => {
                this.error_message = 'Error signing in'
                this.session_data.apiErrorHandler(error);
                this.session_data.Router.navigate(['/login-alternative'])
            }
        )
    }

	ngOnDestroy() {
		if (this.get_login_sub) this.get_login_sub.unsubscribe();
	}
}
