import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'app/shared/classes/index';
import { SessionDataService, GlobalService } from 'app/shared/services';
import { VirtualScrollManager } from 'app/shared/classes/virtual-scroll-manager';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { projectSort } from 'app/shared/classes/sort-list-interfaces';
import { FeatureFlags, LaunchDarklyService } from 'app/shared/services/launch-darkly.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'qm-list-projects',
	templateUrl: './list-projects.component.html',
	styleUrls: ['./list-projects.component.scss'],
	providers: [LaunchDarklyService]
})
export class ListProjectsComponent implements OnInit {
	private is_kronos_released: boolean = false;

	project_filters: Object = {};

	private route_data$: Subscription;

	public csv_loading : boolean = false;

	private virtual_scroll_manager: VirtualScrollManager;

	readonly project_query_url = '/projects/query';

	@ViewChild('filterbox') filterbox: ElementRef;

	constructor(private session_data: SessionDataService, private featureFlagging: LaunchDarklyService, private route: ActivatedRoute, private global_service: GlobalService) {
		this.route_data$ = this.route.data.subscribe(data => {
			if (data.project_filters) {
				this.project_filters = data.project_filters;
			}
		});
		this.featureFlagging.loadAll().then(() => {
			this.is_kronos_released = this.featureFlagging.get(FeatureFlags.KronosActivated);
			}).catch(err => console.log({err}));
	}

	ngOnInit() {
		this.session_data.Title = "Projects";
		let default_sort: projectSort = "date_created:desc"
		this.virtual_scroll_manager = new VirtualScrollManager(this.session_data, this.project_query_url, Project.dbImport, "project_filters_v2", default_sort,  this.project_filters);
	}

	onOpenFilterBy(field:string, event){
		if (this.virtual_scroll_manager.current_filter !== undefined){
			this.virtual_scroll_manager.current_filter = undefined;
			return;
		}
		switch (field) {
			case 'status':
				this.virtual_scroll_manager.setFilterOptions(this.global_service.ProjectStatuses.map(status => {
					return  {'label': status, 'selected': false, 'value': status}
				}), "status");
			break;
			case 'invoicing_status':
				this.virtual_scroll_manager.setFilterOptions(this.global_service.InvoicingStatuses.map(status => {
					return {'label': status, 'selected': false, 'value': status}
				}), "invoicing_status",
				{'label':'No Invoicing Status', 'selected':false, 'value': null});
			break;
			case 'members.sales_rep.id':
				this.virtual_scroll_manager.setFilterOptions(this.session_data.getUsersByGroup("sales").map(rep => {
					return  {'label': rep.name, 'selected': false, 'value': rep.id}
				}), "members.sales_rep.id");
			break;
			case 'study_type':
				this.virtual_scroll_manager.setFilterOptions(this.global_service.StudyTypes.map(status => {
					return  {'label': status, 'selected': false, 'value': status}
				}), "study_type");
			break;
			case 'members.project_manager.id':
				this.virtual_scroll_manager.setFilterOptions(this.session_data.getUsersByGroup("project_managers")
					.map(rep => {
						return  {'label': rep.name, 'selected': false, 'value': rep.id}
					}
				), "members.project_manager.id");
			break;
			case 'host_survey':
				this.virtual_scroll_manager.setFilterOptions([
					{'label': 'Quest', 'selected': false, 'value': true},
					{'label': 'Client', 'selected': false, 'value': false}
				], "host_survey");
			break;
			case 'is_wholesale':
				this.virtual_scroll_manager.setFilterOptions([{label: 'Yes', selected: false, value: true}, {label: 'No', selected: false, value: false}], "is_wholesale");
			break;
		}
		event.target.parentElement.appendChild(this.filterbox.nativeElement);
	}

	exportToCSV() {
		this.csv_loading = true;

		let url = `${this.project_query_url}?sort=${this.virtual_scroll_manager.sort}&limit=${this.virtual_scroll_manager.total_item_count}`;
		if (this.virtual_scroll_manager.search_text !== undefined) {
			url+= "&search=" + encodeURIComponent(this.virtual_scroll_manager.search_text);
		}
		url+="&format=csv&localTimeOffset=" + moment().utcOffset();
		let filename = 'projects';
		if(this.virtual_scroll_manager.filterState['date_created']){
			let date_filter =this.virtual_scroll_manager.filterState['date_created'];
			filename += '_' + moment(date_filter.startDate).format('YYYY-MM-DD') + '_to_' + moment(date_filter.endDate).format('YYYY-MM-DD');
		}

		filename += ".csv";

		this.session_data.apiPostDownload(url, this.virtual_scroll_manager.filterState).subscribe(
			data =>{
				saveAs(data.blob(),filename);
			},
			(err) => {this.csv_loading = false; this.session_data.apiErrorHandler(err); },
			() =>  this.csv_loading = false
		);
	}

	onSelect(project_id: string){
		let path = this.session_data.Router.url;
		path = path.replace("/generated", "");
		if (path.slice(-8) !== "projects") path +="projects";
		this.session_data.Router.navigate([path, project_id, "info"], {relativeTo: this.route});
	}

	onCreate(){
		if (this.is_kronos_released) {
			return window.location.href = `${environment.kronos_web_url}/work-orders/create`;
		}

		let path = this.session_data.Router.url;
		path = path.replace("/generated", "");
		if (path.slice(-8) !== "projects") path +="projects";
		this.session_data.Router.navigate([path, "new"], {relativeTo: this.route});
	}

	ngOnDestroy(){
		this.virtual_scroll_manager.destroy();
		this.route_data$.unsubscribe();
	}
}
