import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionDataService } from 'app/shared/services';
import { Subscription } from 'rxjs/Subscription';


const client_id = "78116867491.1846637582688";
const slack_url_base = "https://slack.com/oauth/authorize?scope=identity.basic,identity.email,identity.avatar";

@Component({
	selector: 'app-login',
	template: `
	<div id="loading" class="full-page-loader" *ngIf="loading; else login">
		<i class="fal fa-spinner-third common-spinner"></i>
		<div>Loading ...</div>
	</div>
	<ng-template #login>
		<div (click)="goSlackLogin()" id="login-container" class="center">
			<img  alt="Sign in with Slack" src="https://platform.slack-edge.com/img/sign_in_with_slack@2x.png"/>
		</div>
	</ng-template>
	`,
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

	public loading: boolean = false;
	private redirect_uri: string;
	private get_login_sub: Subscription;

	constructor(private route: ActivatedRoute, private session_data: SessionDataService) { }

	ngOnInit() {
		if (this.session_data.isAuthenticated) {
			this.session_data.Router.navigate(['/']);
		}
		this.session_data.Title = "Quest Titan";
		this.session_data.ExpiryWarning = false;
		this.session_data.hideNav = true;
		this.redirect_uri = window.location.origin + '/login';

		let code = this.route.snapshot.queryParams["code"];
		if (code !== undefined) {
			this.loading = true;
			this.getAccessToken(code);
		}
	}

	goSlackLogin() {
		let slack_url = slack_url_base + `&client_id=${client_id}&redirect_uri=${this.redirect_uri}`;
		window.location.href = slack_url;
		this.loading = true;
	}

	getAccessToken(code) {
		this.get_login_sub = this.session_data.apiGet(`/login?client_id=${client_id}&code=${code}&redirect_uri=${this.redirect_uri}`).subscribe(
			data => {
				this.session_data.initializeApp(data['result']);
			},
			error => {
				this.session_data.apiErrorHandler(error);
				this.session_data.Router.navigate(['/login']);
			}
		);
	}

	ngOnDestroy() {
		if (this.get_login_sub) this.get_login_sub.unsubscribe();
	}
}
