import { Component } from '@angular/core';
import { SessionDataService } from 'app/shared/services';

@Component({
	selector: 'app-portal-home',
	templateUrl: './portal-home.component.html',
	styleUrls: ['./portal-home.component.scss']
})
export class PortalHomeComponent {
	constructor(private session_data: SessionDataService) {
		this.session_data.Title = "Quest Titan";
	}
}
