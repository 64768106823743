
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
	selector: 'qm-progress-circle',
	templateUrl: './progress-circle.component.html',
	styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnChanges, OnInit {

	private pi = Math.PI;
	private radius : number;
	private center: number;
	private progress_offsets: string[]; //[] = [];
	private font_size: string;

	// clrs:[ // from http://clrs.cc/
	// 	"#001f3f", // Navy
	// 	"#0074D9", // BLUE
	// 	"#FF851B", // ORANGE
	// 	"#FF4136", // RED
	// 	"#85144b", // MAROON
	// 	"#F012BE", // FUCHSIA
	// 	"#B10DC9", // PURPLE
	// 	"#111111", // BLACK
	// 	"#AAAAAA", // GRAY
	// 	"#DDDDDD" // SILVER
	// ]


	@Input('show_number') show_number: boolean = false;
	@Input('width') width: number = 200;
	@Input('stroke') stroke_width: number = 20;
	@Input('progress_bars') progress_bars: number[] = [0]; //[];
	@Input('progress_numbers') progress_numbers: number[] = []; //[];
	@Input('labels') labels: string[]; //[];
	@Input('colors') colors: string[]= [
		"#ffb347",
		"#cdf5b8",
		"#ffbdbd",
		"#c9c9ff",
		"#039be5",
		"#c9c9ff",
		"#ff7676",
		"#47b9ff"
	];
	private tool_tip_labels: string[];
	constructor() {}


	ngOnChanges(changes) {
		this.radius = (this.width / 2) - (this.stroke_width / 2);
		this.center = this.width / 2;
		// console.log("progress_bars",this.progress_bars);

		this.tool_tip_labels = [];
		this.progress_offsets = [];
		let progress_offset_numbers = []; // Calculate number of degrees to rotate each progress circle
		let progress_step = 0; // Sum of the previous progresses
		// console.log("progress_bars",this.progress_bars);
		for(let i = 0; i < this.progress_bars.length; i++){
			if(i == 0)
				progress_offset_numbers[i] = 0;
			else{
				progress_offset_numbers[i] = (360 * (progress_step));
			}
			progress_step += this.progress_bars[i];
			if(this.labels)
				this.tool_tip_labels[i] = `${this.labels[i]} (${(this.progress_bars[i]*100).toFixed()}%)`
			else
				this.tool_tip_labels[i] = `${(this.progress_bars[i]*100).toFixed()}%`
		}
		this.progress_offsets = progress_offset_numbers.map(x => `rotate(${x}deg)`);
		// console.log("progress_offset_numbers",progress_offset_numbers);
		// console.log("progress_offsets",this.progress_offsets);


		let hole_width = this.width - this.stroke_width;
		if (hole_width <= 50){
			this.font_size = "16px";
		}else if (hole_width <= 70){
			this.font_size = "24px";
		}else if (hole_width <= 100){
			this.font_size = "30px";
		}else{
			this.font_size = "36px";
		}
	}

	ngOnInit() {
		// Generate random colors if we don't have enough provided
		if(this.colors.length < this.progress_bars.length){
			for(let i in this.progress_bars){
				if(this.colors[i] === undefined){
					this.colors[i] = this.generateColor();
				}
			}
		}
	}

	generateColor(): string{
		let letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

}
