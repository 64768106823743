import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionDataService } from 'app/shared/services';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as ReportsActions from 'app/store/reports/reports.actions';
import * as fromReports from 'app/store/reports/reports.reducers';

@Component({
	selector: 'qm-navbar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.scss']
})

export class NavigationBarComponent implements OnInit, OnDestroy {

	private redirect_uri = "titan.dev.questshare-group.com";

	private confirm_sub;
	private reports_state: Observable<fromReports.State> ;
	constructor(private session_data: SessionDataService, private store: Store<fromReports.State>) { }

	ngOnInit(){
		this.store.dispatch(new ReportsActions.LoadDashboards());
		this.reports_state = this.store.select('reports_state');
	}

	confirmLogout(){
		this.confirm_sub = this.session_data.confirmationCheck(
			"Are you sure you want to logout?"
		).subscribe(data => {
			if(data === true){
				this.logoutTitan();
			}
		})
	}

	logoutTitan(){
		this.session_data.logOut();
	}

	stopImpersonation(){
		console.log("Stopping Impersonation");
		this.session_data.ImpUser = undefined;
	}

	ngOnDestroy(){
		if(this.confirm_sub) this.confirm_sub.unsubscribe();
	}
}
