import { Reference } from "app/shared/interfaces";
import { AuthConstraint } from 'app/shared/classes/auth-constraint';


export class Note {

	private content: string;
	private author: Reference;
	private parent_id: string;
	private date_created: string;

	public id: Function;
	public setID(_id: string){
		this.id = () => _id;
	}


	get TimeStamp()  { return new Date(this.date_created); }
	get Author() : Reference { return this.author; }
	get Content() : string { return this.content; }


	constructor(_id: string) {
		this.id = () => _id;
		this.date_created = new Date().toISOString().slice(0, 16) + 'Z';
	}

	static dbImport(source: object){
		let note = new Note(source['_id']);
		for (let property of Object.keys(source)){
			if(property == '_id') continue;
			note[property] = source[property];
		}
		return note;
	}

}
