import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'qm-daterangepicker',
  template: `
    <div *ngIf="inline;else inline_datepicker">
        <ngx-daterangepicker-material
            [ranges]="ranges"
            alwaysShowCalendars="true"
            showCustomRangeLabel="true"
            linkedCalendars="true"
            autoApply="true"
            keepCalendarOpeningWithRange="true"
            [maxDate]="maxDate"
            (choosedDate)="filter_by_date.emit($event)"
            (startDateChanged)="startDateChanged.emit($event)"
            (rangeClicked)="rangeClicked.emit($event)">
        </ngx-daterangepicker-material>
    </div>
    <ng-template #inline_datepicker>
        <div class="daterange-input-container">
        <input type="text"
            ngxDaterangepickerMd
            [(ngModel)]="selectedDates"
            (ngModelChange)="filter_by_date.emit(selectedDates)"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [showClearButton]="true"
            [opens]="opensConfig"
            placeholder="Filter by creation date..."
        >
        </div>
        <i class="far fa-calendar"></i>
    </ng-template>
	`,
  styleUrls: ['./daterangepicker.component.scss']
})
export class DateRangePicker implements OnInit {
  private maxDate: moment.Moment = moment().add(1, 'year');
  private selected: any;
  private opensConfig: string = 'left';
  private ranges: any = {
    'Today': [moment().startOf('day'), moment()],
    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  @Input() inline: boolean = false;
  @Input('initialDateRange') set InitialDateRange(range: any) {
    if (range) {
      this.selected = range;
    }
  }

  @Output() filter_by_date = new EventEmitter();
  @Output() startDateChanged = new EventEmitter();
  @Output() rangeClicked = new EventEmitter();

  ngOnInit() {
  }
}
