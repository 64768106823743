import { Component, OnInit, ViewChild, ElementRef, Input, forwardRef } from '@angular/core';
import { NgModel, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';


const noop = () => {};
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AdaptableTextareaComponent),
    multi: true
};


@Component({
  selector: 'qm-adaptable-textarea[ngModel], qm-adaptable-textarea[formControlName]',
  templateUrl: './adaptable-textarea.component.html',
  styleUrls: ['./adaptable-textarea.component.scss'],
  providers: [
	  CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR,
	  { provide: NG_VALIDATORS, useExisting: forwardRef(() => AdaptableTextareaComponent), multi: true }
  ]
})
export class AdaptableTextareaComponent implements ControlValueAccessor, Validator {

	private text: string = "";
	private num_textarea_rows: number = 1;

	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	@Input() placeholder: string = "";
	@ViewChild('textareaMirror') mirror: ElementRef;

	constructor() { }

	autoResize(){
		this.num_textarea_rows = Math.round((this.mirror.nativeElement.offsetHeight - 6) / 22);
		if (this.num_textarea_rows == 0) ++this.num_textarea_rows;
	}

	get Text(): string { return this.text; };
	set Text(value : string){
		if (value !== this.text){
			this.text = value;
			this.onChangeCallback(value);
		}
	}

	writeValue(value: any){
		this.text = value;
		setTimeout(()=>this.autoResize(), 0);
	}
	registerOnChange(fn: any) { this.onChangeCallback = fn; }
	registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

	validate(c: AbstractControl) : { [key: string]: any } {
		let value = c.value;
		if (c.value == null){
			return {"required": false};
		}
		return null;
	}

}
