import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'titleize'
})
export class TitleizePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if(value)
			return value.split('_').map(x => `${x[0].toUpperCase()}${x.slice(1)}`).join(' ');
		else
			return null;
	}
}
