export function extractError(error){
	let e_body;
	try{
		e_body = JSON.parse(error._body).result;
		e_body.status = error.status;
	}catch(e){
		console.log("Error Not JSON",error);
		e_body = {status: error.status};
	}
	return e_body;
}
