import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LDClient, LDUser, initialize as initializeLaunchDarkly } from "launchdarkly-js-client-sdk";
import { environment } from '../../../environments/environment';
import { SessionDataService } from './session-data.service';

@Injectable()
export class LaunchDarklyService {
    public client: LDClient;
    private user: LDUser;
    private initialized: boolean = false;

    private allFlagVals: object = null;

    constructor(private route: ActivatedRoute, private session_data: SessionDataService) {
        let userKey: string = this.session_data.CurrentUser.SlackID;

        this.user = {
            key: userKey
        };
        
        this.client = initializeLaunchDarkly(environment.launchDarklyClientSideId, this.user);
    }

    get(flag_name: string, defaultVal?: boolean) {
        if (!this.allFlagVals) {
            throw new Error('You may not call the "get" method until after the Feature Flag Service has been initialized!');
        }

        return this.allFlagVals.hasOwnProperty(flag_name) ? this.allFlagVals[flag_name] : defaultVal;
    }


    async isFeatureActivated(flag_name: string, defaultVal: boolean = false): Promise<boolean> {
        if (this.allFlagVals != null && Object.keys(this.allFlagVals).includes(flag_name)) {
            return this.allFlagVals[flag_name];
        }

        if (!this.initialized) {
            await this.client.waitForInitialization();
            this.initialized = true;
        }

        let result: boolean;

        try {
            result = this.client.variation(flag_name, defaultVal);
        }
        catch (e) {
            console.error(`Failed to retrieve feature flag "${flag_name}" from LaunchDarkly!`, e);
            result = defaultVal;
        }

        return result;
    }

    async loadAll(): Promise<object> {
        if (!this.initialized) {
            await this.client.waitForInitialization();
            this.initialized = true;
        }

        try {
            this.allFlagVals = this.client.allFlags();
        }
        catch (e) {
            console.error('Attempt to load all feature flags failed!', e);
        }
        finally {
            return this.allFlagVals;
        }
    }
}

export const FeatureFlags = {
    KronosActivated: 'kronos-activated',
};

