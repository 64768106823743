import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalService } from "app/shared/services";

import { PortalHomeComponent } from './portal-home/portal-home.component';

import { LoginComponent } from 'app/navigation/login/login.component';
import { LoginAlternativeComponent } from 'app/navigation/login-alternative/login-alternative.component';
import { PrescreenerComponent } from 'app/prescreener/prescreener.component';
import { UserInfoComponent } from 'app/navigation/user-info/user-info.component';
import { ReportBugComponent } from 'app/report-bug/report-bug.component';


import { ProjectsModule } from 'app/projects/projects.module';
import { TeamModule } from 'app/team/team.module';

import { AuthGaurd } from './navigation/auth-gaurd.service';
import { GroupGaurd } from 'app/shared/services/group-gaurd.service';
import { PrescreenerResolverService } from 'app/prescreener/prescreener-resolver.service';


// Error
import { ErrorPageComponent } from 'app/navigation/error-page/error-page.component';


const APP_ROUTES: Routes = [
	{
		path: '',
		component: PortalHomeComponent,
		canActivate: [AuthGaurd],
		data: { "modules": ["projects"], "project_filters": { "api_generated": [false, null] } },
		resolve: {globals: GlobalService}
	},
	{ path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGaurd, GroupGaurd], data: { "groups": ["admin"]}},
	{ path: 'reports', loadChildren: './reports/reports.module#ReportsModule', canActivate: [AuthGaurd, GroupGaurd], data: { "groups": ["reports"]}},
	{ path: 'user', component: UserInfoComponent, canActivate: [AuthGaurd]},
	{ path: 'issues', component: ReportBugComponent, canActivate: [AuthGaurd]},
	{ path: 'issues/:issue_id', component: ReportBugComponent, canActivate: [AuthGaurd]},
	{ path: 'login', component: LoginComponent},
	{ path: 'login-alternative', component: LoginAlternativeComponent},
	{ path: 'team', loadChildren: './team/team.module#TeamModule', canActivate: [AuthGaurd]},
	{
		path: 'sales',
		loadChildren: './sales/sales.module#SalesModule',
		canActivate: [AuthGaurd, GroupGaurd],
		data: { "groups": ["admin", "sales"], "modules": ["sales"]},
		resolve: {globals: GlobalService}
	},
	{
		path: 'finance',
		loadChildren: 'app/finance/finance.module#FinanceModule',
		canActivate: [AuthGaurd, GroupGaurd],
		data: {"groups": ["finance"], "modules": ["finance"] },
		resolve: {globals: GlobalService}
	},
	// { path: 'mailchimp', loadChildren: './mailchimp/mailchimp.module#MailchimpModule', canActivate: [AuthGaurd, GroupGaurd], data: { "groups": ["admin"]}},
	{ path: 'panels', loadChildren: './panels/panels.module#PanelsModule', canActivate: [AuthGaurd]},
	{ path: 'feasibility-checker', loadChildren: './feasibility-checker/feasibility-checker.module#FeasibilityCheckerModule', canActivate: [AuthGaurd] },
	{
		path: 'projects',
		loadChildren: './projects/projects.module#ProjectsModule',
		canActivate: [AuthGaurd],
		data: {"modules": ["projects"]},
		resolve: {globals: GlobalService}
	},
	{
		path: 'prescreener', loadChildren: './prescreener/screener.module#ScreenerModule',
		canActivate: [AuthGaurd],
		data: {"modules": ["prescreener"]},
		resolve: {globals: GlobalService}
	},
	{
		path: 'campaigns',
		loadChildren: './campaigns/referral-campaigns.module#ReferralCampaignsModule',
		canActivate: [AuthGaurd, GroupGaurd],
		data: { "groups": ["admin", "referral_campaigns"], "modules": ["campaigns"]},
		resolve: {globals: GlobalService}
	},
	{ path: 'not-found', component: ErrorPageComponent, data: {message: 'Page not found'} },
	{ path: '**', redirectTo: '/not-found' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(APP_ROUTES,{
			paramsInheritanceStrategy: 'always'
		})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {}
