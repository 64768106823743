import { KeyValue } from 'app/shared/interfaces';
export class QueryParams{
	private from: number;
	private limit: number;
	private sort: string[];
	private fields: string[];
	private exclude_fields: string[];
	private extra_fields: KeyValue[];

	constructor(params: {
		from?: number,
		limit?: number,
		sort?: string[],
		fields?: string[],
		exclude_fields?: string[],
		extra_fields?: KeyValue[],
	}={}){
		this.from = params.from;
		this.limit = params.limit;
		this.sort = params.sort;
		this.fields = params.fields;
		this.exclude_fields = params.exclude_fields;
		this.extra_fields = params.extra_fields;
	}

	toString(){
		let query = '';
		if(this.from !== undefined) query += `&from=${this.from}`;
		if(this.limit !== undefined) query += `&limit=${this.limit}`;
		if(Array.isArray(this.sort)) query += `&sort=${this.sort.join(',')}`;
		if(Array.isArray(this.fields)) query += `&fields=${this.fields.join(',')}`;
		if(Array.isArray(this.exclude_fields)) query += `&exclude_fields=${this.exclude_fields.join(',')}`;
		if(Array.isArray(this.extra_fields)){
			query += this.extra_fields.map(p => `&${p.key}=${p.value}`).join('');
		}
		// console.log("Made Query String",query);
		return query;
	}
}
