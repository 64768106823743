import { Component, Input, Output, OnDestroy, OnInit, EventEmitter} from '@angular/core';

// used for manual, efficiene debouce. http://stackoverflow.com/a/36849347/6775631
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
	selector: 'qm-searchbar',
	template: `
		<input type="text" placeholder="type to search..." [formControl]="search_control" required="required">
		<i class="fa fa-search"></i>
		<i class="fa fa-times" (click)="clear()"></i>
	`,
	styleUrls: ['./searchbar.component.scss']
})
export class SearchBar implements OnDestroy, OnInit {

	private initial_text: string;

	@Input('initialText') set InitialText(text: string){
		this.initial_text = text;
		if(this.initial_text) this.search_control.setValue(text, {emitEvent: false});
	}

	@Output() search_text = new EventEmitter();

	private search_control = new FormControl();
	private vc_sub: Subscription;

	constructor() {
		this.vc_sub = this.search_control.valueChanges.pipe(debounceTime(300)).subscribe(
			data =>	this.search_text.emit(data)
		);
	}
	clear(){
		this.search_control.reset();
	}
	ngOnInit(){
		if(this.initial_text) this.search_control.setValue(this.initial_text);
	}
	ngOnDestroy(){
		if(this.vc_sub) this.vc_sub.unsubscribe();
	}
}
