import { Component, OnInit } from '@angular/core';
import { User } from 'app/shared/classes';
import { SessionDataService } from 'app/shared/services';


@Component({
	selector: 'qm-user-info',
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit{

	private api_tokens: {token: string, expiry: Date}[] = [];
	private user: User;

	constructor(private session_data: SessionDataService) { }

	ngOnInit(){
		this.user = this.session_data.CurrentUser;
		this.loadTokens();
	}

	loadTokens(){
		this.session_data.apiGet(`/users/${this.session_data.CurrentUser.id()}/api-tokens`).subscribe(
			data => {
				this.api_tokens = data.result;
			},
			this.session_data.apiErrorHandler
		);
	}

	logUser(){
		console.log("CurrentUser",this.session_data.CurrentUser);
	}

	generateToken(){
		this.session_data.apiPost(`/users/${this.session_data.CurrentUser.id()}/api-tokens`,{}).subscribe(
			data => {
				this.api_tokens.push(data.result);
			},
			this.session_data.apiErrorHandler
		);
	}

	deleteToken(index:number){
		this.session_data.apiDelete(`/users/${this.api_tokens[index]['_id']}/api-tokens`).subscribe(
			data => {
				this.api_tokens.splice(index,1);
			},
			this.session_data.apiErrorHandler
		);
	}
}
