import { Accessor } from 'app/shared/interfaces';
import { User } from 'app/shared/classes';

export abstract class AuthConstraint {

	public id: Function;

	public setID(_id: string){
		this.id = () => _id;
	}

	protected members: Accessor[] = [];
	protected groups: Accessor[] = [];
	abstract setDefaultMembers();

	get Members() : Accessor[] { return this.members; }
	set Members(members: Accessor[]) { this.members = members; }

	get Groups() : Accessor[] { return this.groups; }
	set Groups(Groups: Accessor[]) { this.groups = Groups;}


	hasWriteAccess(user: User) {
		if (user.Role == "Admin") return true;
		for (let member of this.members){
			if (member.id == user.id() && member.scopes.indexOf("write") !== -1) return true;
		}
		for (let group of this.groups){
			for (let user_group of user.Groups){
				if (user_group == group.name && group.scopes.indexOf("write") !== -1) return true;
			}
		}
		return false;
	}

	hasReadAccess(user: User){
		if (user.Role == "Admin") return true;
		for (let member of this.members){
			if (member.id == user.id()) return true;
		}
		for (let group of this.groups){
			for (let user_group of user.Groups)
				if (user_group == group.name) return true;
		}
		return false;
	}

	findMemberByRole(role: string): number{
		for(let i=0; i<this.members.length; i++)
			if(this.members[i].role === role) return i;
		return -1;
	}

	constructor(_id?: string){
		this.id = () => _id;
	}

	deepCopyPermissions() : AuthConstraint{
		let permissions = Object.create(AuthConstraint.prototype);
		permissions.Groups = this.groups.map(a=><Accessor> Object.assign({}, a));
		permissions.Members = this.members.map(a=><Accessor> Object.assign({}, a));
		return <AuthConstraint> permissions;
	}

	get Permissions(){
		return {
			'members': this.members,
			'groups': this.groups
		};
	}

}
