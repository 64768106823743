import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { take, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { Actions, Effect } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import * as ReportsActions from 'app/store/reports/reports.actions';
import * as fromReports from 'app/store/reports/reports.reducers';

import { SessionDataService } from 'app/shared/services/session-data.service';
import { extractError } from 'app/shared/services/extract-error';

import { QueryParams } from 'app/shared/classes';
import { ApiResponse } from 'app/shared/interfaces';

@Injectable()
export class ReportsEffects{

	@Effect()
	load_dashboards = this.actions$
	.ofType(ReportsActions.LOAD_DASHBOARDS)
	.pipe(
		switchMap(() => {
			return this.session_data.apiGet(`/reports/dashboards`)
			.pipe(
				map((data: ApiResponse) => {
					return new ReportsActions.LoadDashboardsSuccess(data.result);
				}),
				catchError((e) => of(new ReportsActions.LoadDashboardsFailure(extractError(e))))
			)
		})
	);

	constructor(private actions$: Actions, private session_data: SessionDataService, private store: Store<fromReports.State>){}
}
